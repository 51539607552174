import { createContext, useContext } from "react";
import { UserContextType } from "../types";

export const UserContext = createContext<UserContextType | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
      throw new Error('useUserContext doit être utilisé à l’intérieur d’un UserContextProvider');
  }
  return context;
};

export default UserContext;