import { GridCenteredRow } from "@killbillsdev/killbills-ui/lib/components/GridCenteredRow"
import { Typography } from "@mui/material"
import { styles } from "../styles"

export const HeadingSubtitle = () => {
    return (
        <GridCenteredRow paddingBottom={9}>
        <Typography fontFamily='Ubuntu' textAlign={'center'} sx={{...styles.headingSubtitleFirstText, fontSize: {xl:20,l:16},}}>
        Vous allez être guidé afin d’intégrer l’API de KillBills. {' '}        </Typography>
        <Typography fontFamily='Ubuntu' textAlign={'center'} sx={{...styles.headingSubtitleSecondText, fontSize: {xl:20,l:16},}}>
        En bas de page, un bouton permet de démarrer l’intégration.
        </Typography>
    </GridCenteredRow>
    )
}