import { Theme } from "@aws-amplify/ui-react";
export const styles = {
    containerStyle: { width: "90vw", height: "70vh",border:2,borderRadius:10},
    containerStyleV2: { width: "100%", height: "100%" },
    breadCrumbChipActive: {
      display: 'flex',
      width: '20px',
      height: '20px',
      padding: '10px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: 'var(--gap-gap-s, 4px)',
      background: 'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
      color: 'var(--colors-text-text-terciary, #120F22)',
      textAlign: 'center',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Ubuntu, sans-serif',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      marginRight:2
    },
    breadCrumbChip: {
      display: 'flex',
      width: '20px',
      height: '20px',
      padding: '10px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: 'var(--gap-gap-s, 4px)',
      backgroundColor: 'rgba(27,24,43,1)',
      color: 'rgba(113,110,125,1)',
      textAlign: 'center',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Ubuntu, sans-serif',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      marginRight:2
    },
    chooseLanguageButton:{"&:hover":{background: 'linear-gradient(104.04deg, #53DECE 0%, #30F698 100%)',color:'rgba(18, 13, 39, 1)'}},
    flexCenter: { display: "flex", justifyContent: "center" },
    col: { flexDirection: "column" },
    chipStyle: {
      fontWeight: "bold",
      color: "white",
      marginRight: 1,
      marginBottom: 1,
      background: "linear-gradient(135deg, rgb(83, 222, 206), rgb(48, 246, 152))",
    },
    mapCardStyle: { width: "90vw", height: "30vh" },
    flexResponsive: { display: { xs: "flex", sm: "none" } },
    footerBox: {
      width: "100%",
      height: "auto",
      backgroundColor: "#120D27",
      paddingTop: "4rem",
      paddingBottom: "3rem",
    },
    w200: { width: 200 },
    w100: { width: "100%" },
    w50: { width: "50%" },
    w25: { width: "25%" },
    m1: { margin: 1 },
    navbarButton: {
      mx: 2,
      alignSelf: "center",
      color: "black",
      fontFamily: "Ubuntu-Bold, sans-serif",
      letterSpacing: "0.24px",
    },
    navbarBox: { xs: "none", sm: "flex" },
    navbarAppBar: { boxShadow: "none", backgroundColor: "white" },
    footerLink: {
      fontFamily: "Ubuntu-Regular,sans-serif",
      letterSpacing: "0.24px",
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "2rem",
    },
    listStoreStyle: {
      maxHeight: "100%",
      overflow: "auto",
      width: "100%",
      //backgroundColor: "rgb(19,14,34,0.55)",
    },
    listItemsStyle: {
      width: "90%",
      backgroundColor: "white",
      borderRadius: 5,
      marginTop: {xs:1,sm:1,md:0,lg:0,xl:0},
      marginBottom: {xs:1,sm:1,md:0,lg:0,xl:0},
      marginLeft:3,
      marginRight:3
    },
    searchBarStyle: {
      backgroundColor: "#ffd60a",
      border: "3px solid #001d3d",
    },
    listContainerStyle: {
      height: "60vh", // Vous pouvez ajuster cette hauteur selon vos besoins
      overflow: "auto",
      width:'90vw'
    },
    boxListStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    menuTypographyMobile: { color: "white", fontWeight: "bold" },
    menuDividerMobile: {
      borderBottomWidth: 5,
      backgroundColor: "rgb(41,244,143)",
    },
    introductionPageStyle: {display:'flex',justifyContent:'center',flexDirection:'column',padding:10},
    banksStepsStyle:{display:'flex',flexDirection:'column',justifyContent:'space-around'},
    banksStepsStepsStyle:{display:'flex',alignItems:'center',paddingTop:1},
    headingSubtitleFirstText:{background:'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',WebkitBackgroundClip: 'text', WebkitTextFillColor: '#fff', MozBackgroundClip: 'text', backgroundClip: 'text', color: '#FFF', fontSize: {xs:10,sm:10,md:12,lg:12,xl:18}, lineHeight: 'normal',paddingBottom:2},
    headingSubtitleSecondText:{whiteSpace:{xs:'wrap',lg:'nowrap'},background:'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',WebkitBackgroundClip: 'text', WebkitTextFillColor: '#fff', MozBackgroundClip: 'text', backgroundClip: 'text', color: '#FFF', fontSize: {xs:10,sm:10,md:12,lg:12,xl:18}, lineHeight: 'normal',paddingBottom:2},
    headingTitleFirstText:{ background:'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',WebkitBackgroundClip: 'text', WebkitTextFillColor: '#fff', MozBackgroundClip: 'text', backgroundClip: 'text', color: '#FFF', fontSize: {xs:28,sm:28,md:37,lg:37,xl:55}, lineHeight: 'normal'},
    headingTitleSecondText:{ fontFamily:'Ubuntu',background: 'linear-gradient(104deg, #53DECE 0%, #30F698 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', MozBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent'},
    howItWorksStyle:{display:'flex',flexDirection:'column',paddingLeft:10,paddingRight:10,paddingTop:5,paddingBottom:10,marginRight:10,marginLeft:10,marginBottom:5},
    howItWorksBoxStyle:{display:'flex',flexDirection:{xs:'column',lg:'row'},justifyContent:{xs:'center',lg:'space-around'},alignItems:{xs:'center',lg:'stretch'}},
    howItWorksCardStyle:{display:'flex',alignItems:'center',justifyContent:'center',overflow:'scroll',margin:2,padding:5,maxWidth:{xs:'100%',sm:'100%',md:250,lg:250,xl:300},height:{xs:100,sm:150,md:250,lg:250,xl:300}},
    flexAlignCenter:{display:'flex',alignItems:'center'},
    pr1:{paddingRight:1},
  };
  
  export const clusterStyles = [
    {
      textColor: "white",
      url: "m1.png",
      height: 50,
      width: 50,
      label: "test",
    },
    {
      textColor: "white",
      url: "m2.png",
      height: 70,
      width: 70,
    },
    {
      textColor: "white",
      url: "m3.png",
      height: 80,
      width: 80,
    },
    {
      textColor: "white",
      url: "m4.png",
      height: 100,
      width: 100,
    },
    {
      textColor: "white",
      url: "m5.png",
      height: 120,
      width: 120,
    },
  ];
  
  export const LocationButtonStyle = (button: any) => {
    button.textContent = "Afficher ma position";
    button.classList.add("custom-map-control-button");
    button.style.backgroundColor = "#fff";
    button.style.border = "2px solid #fff";
    button.style.borderRadius = "3px";
    button.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    button.style.color = "rgb(25,25,25)";
    button.style.cursor = "pointer";
    button.style.fontFamily = "Ubuntuo,Arial,sans-serif";
    button.style.fontSize = "16px";
    button.style.lineHeight = "38px";
    button.style.margin = "8px 0 22px";
    button.style.padding = "0 5px";
    button.style.textAlign = "center";
    return button;
  };
  
  export const dividerTheme: Theme = {
    name: 'divider-theme',
    tokens: {
      components: {
        divider: {
          borderColor: { value: 'rgb(121, 289, 181)' },
          label:{
            color:{value: 'rgb(255, 255, 255)'},
            backgroundColor: { value: 'rgb(13,11,24)' },
        }
        },
      },
    },
};