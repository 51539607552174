import { Grid, Hidden } from '@mui/material';

export const GridPanelRow = ({
    leftChildren,
    rightChildren,
    paddingBottom = 0,
    paddingTop = 0,
}: any) => {
    return (
        <Grid
            xs={12}
            container
            item
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
        >
            <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                {leftChildren}
            </Grid>
            <Hidden mdDown>
                <Grid item sm={0} xs={0} md={6} lg={6}>
                    {rightChildren}
                </Grid>
            </Hidden>
        </Grid>
    )
}