import { CopyField } from "@killbillsdev/killbills-ui";
import { Grid } from "@mui/material";
import { useUserContext } from "../contexts/NavigationContext";

export const  GetHmacKey = ({groups,hmac}:any) => {

    const { integrationOption,languageOption } = useUserContext()
    const partnerName = groups?.includes('bank')
        ? groups.filter((v: string)=>(v!=='bank'))[0]
        : groups?.includes('pos')
        ? groups.filter((v: string)=>(v!=='pos'))[0]
        : 'none'
    const instructions:any = {
        NodeJS: {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le sdk avec : ' value='npm i @killbillsdev/node-sdk' />,
            sendReceipt: <CopyField sx={{paddingBottom:2}} textArea language='javascript' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :'
            value={`import { sendReceipt } from '@killbills-dev/killbills-sdk';

            const main = async () => {
                const hmacKey = '${hmac}';
                const receiptData = receiptData = {
                    reference_id: '', // string (alphanumérique)
                    amount: 0, // number
                    total_tax_amount: '', // number ou vide
                    currency: '', // string ('EUR' ou 'USD')
                    date: '', // string (au format 'YYYY-MM-DDTHH:mm:ss')
                    covers: 0, // number ou vide
                    table: '', // string ou vide
                    invoice: 0, // number ou vide
                    total_discount: 0, // number ou vide
                    mode: 0, // number ou vide
                    partner_name: '${partnerName}', // string
                    merchant: {
                        merchant_name: '', // string ou vide
                        reference_id: '', // string
                        merchant_id: 0 // number ou vide
                    },
                    store: {
                        store_name: '', // string
                        reference_id: '', // string
                        billing_descriptor: '', // string
                        siret: '', // string (14 caractères)
                        code_ape: '', // string ou vide
                        tva_intra: '', // string ou vide
                        address: {
                            postal_code: 0, // number
                            street_address: '', // string ou vide
                            country: '', // string ou vide
                            city: '', // string ou vide
                            full_address: '', // string ou vide
                            number: 0 // number ou vide
                        }
                    },
                    taxes: [{ 
                        description: '', // string ou vide
                        amount: 0, // number
                        rate: 550 // number (550, 1000 ou 2000) ou vide
                    }],
                    items: [{
                        reference_id: '', // string ou vide
                        name: '', // string
                        description: '', // string ou vide
                        type: '', // string ou vide
                        quantity: 0, // number
                        price: 0, // number
                        discount: 0, // number ou vide
                        total_amount: 0, // number ou vide
                        tax: {
                            description: '', // string ou vide
                            amount: 0, // number
                            rate: 550 // number (550, 1000 ou 2000) ou vide
                        },
                        subitems: [{
                            reference_id: '', // string ou vide
                            name: '', // string
                            description: '', // string ou vide
                            quantity: 0, // number ou vide
                            price: 0, // number ou vide
                            discount: 0, // number ou vide
                            total_amount: 0, // number ou vide
                            tax: {
                                description: '', // string ou vide
                                amount: 0, // number
                                rate: 550 // number (550, 1000 ou 2000) ou vide
                            }
                        }]
                    }],
                    payments: [{
                        bin: '', // string ou vide
                        last_four: '', // string ou vide
                        auth_code: '', // string ou vide
                        scheme: '', // string ou vide
                        amount: 0, // number
                        transaction_date: '', // string (au format 'YYYY-MM-DDTHH:mm:ss')
                        transaction_id: '', // string ou vide
                        payment_type: '' // string ou vide
                    }]
                };
            
                const sendReceiptToKillbills = await sendReceipt('preprod',receiptData,hmacKey);
            
                return sendReceiptToKillbills;
            };`}
            />,
            sendTransaction: <CopyField sx={{paddingBottom:2}} textArea language='javascript' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :'
            value={`import { sendBankingTransaction } from '@killbills-dev/killbills-sdk';

const main = async () => {
            
    const hmacKey = '${hmac}';            
    const transactionData = transactionData = {
        bank_id: '${partnerName}', // string (36 caractères)
        callback_url: '', // string
        partner_name: '${partnerName}', // string
        kb_features: [], // tableau de chaînes de caractères ou nombres vides
        receipt_format: '', // string ('JSON', 'PDF', 'SVG', 'PNG')
        transaction: {
            reference_id: '', // string
            amount: '', // number (positif)
            customer_id: '', // string
            transaction_date: '', // date (au format chaîne de caractères)
            store_name: '', // string ou vide
            billing_descriptor: '', // string
            siret: '', // string ou vide
            payment: '', // objet vide
            currency: '', // string ou vide
            pos_name: '', // string ou vide
            merchant_name: '' // string ou vide
        }
    };            
    const sendTransaction = await sendBankingTransaction('preprod',transactionData,hmacKey);
            
    return sendTransaction;
                
};`}
            />
    
        },
        Python: {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le sdk avec : ' value='pip install killbills-sdk' />,
            sendReceipt: <CopyField sx={{paddingBottom:2}} textArea language='python' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={`from sendReceipt import send_receipt
            receiptData = receiptData = {
                'reference_id': '',  # string (alphanumérique)
                'amount': 0,  # number
                'total_tax_amount': '',  # number ou vide
                'currency': '',  # string ('EUR' ou 'USD')
                'date': '',  # string (au format 'YYYY-MM-DDTHH:mm:ss')
                'covers': 0,  # number ou vide
                'table': '',  # string ou vide
                'invoice': 0,  # number ou vide
                'total_discount': 0,  # number ou vide
                'mode': 0,  # number ou vide
                'partner_name': '${partnerName}',  # string
                'merchant': {
                    'merchant_name': '',  # string ou vide
                    'reference_id': '',  # string
                    'merchant_id': 0  # number ou vide
                },
                'store': {
                    'store_name': '',  # string
                    'reference_id': '',  # string
                    'billing_descriptor': '',  # string
                    'siret': '',  # string (14 caractères)
                    'code_ape': '',  # string ou vide
                    'tva_intra': '',  # string ou vide
                    'address': {
                        'postal_code': 0,  # number
                        'street_address': '',  # string ou vide
                        'country': '',  # string ou vide
                        'city': '',  # string ou vide
                        'full_address': '',  # string ou vide
                        'number': 0  # number ou vide
                    }
                },
                'taxes': [{ 
                    'description': '',  # string ou vide
                    'amount': 0,  # number
                    'rate': 550  # number (550, 1000 ou 2000) ou vide
                }],
                'items': [{
                    'reference_id': '',  # string ou vide
                    'name': '',  # string
                    'description': '',  # string ou vide
                    'type': '',  # string ou vide
                    'quantity': 0,  # number
                    'price': 0,  # number
                    'discount': 0,  # number ou vide
                    'total_amount': 0,  # number ou vide
                    'tax': {
                        'description': '',  # string ou vide
                        'amount': 0,  # number
                        'rate': 550  # number (550, 1000 ou 2000) ou vide
                    },
                    'subitems': [{
                        'reference_id': '',  # string ou vide
                        'name': '',  # string
                        'description': '',  # string ou vide
                        'quantity': 0,  # number ou vide
                        'price': 0,  # number ou vide
                        'discount': 0,  # number ou vide
                        'total_amount': 0,  # number ou vide
                        'tax': {
                            'description': '',  # string ou vide
                            'amount': 0,  # number
                            'rate': 550  # number (550, 1000 ou 2000) ou vide
                        }
                    }]
                }],
                'payments': [{
                    'bin': '',  # string ou vide
                    'last_four': '',  # string ou vide
                    'auth_code': '',  # string ou vide
                    'scheme': '',  # string ou vide
                    'amount': 0,  # number
                    'transaction_date': '',  # string (au format 'YYYY-MM-DDTHH:mm:ss')
                    'transaction_id': '',  # string ou vide
                    'payment_type': ''  # string ou vide
                }]
            }
            
            print(send_receipt('preprod', receiptData,'${hmac}' ))`} />,
            sendTransaction: <CopyField sx={{paddingBottom:2}} textArea language='python' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={`import killbills_sdk
            from killbills_sdk.sendTransaction import send_transaction
            
            payload = transactionData = {
                'bank_id': '${partnerName}',  # string (36 caractères)
                'callback_url': '',  # string
                'partner_name': '',  # string
                'kb_features': [],  # tableau de chaînes de caractères ou nombres vides
                'receipt_format': '',  # string ('JSON', 'PDF', 'SVG', 'PNG')
                'transaction': {
                    'reference_id': '',  # string
                    'amount': '',  # number (positif)
                    'customer_id': '',  # string
                    'transaction_date': '',  # date (au format chaîne de caractères)
                    'store_name': '',  # string ou vide
                    'billing_descriptor': '',  # string
                    'siret': '',  # string ou vide
                    'payment': '',  # objet vide
                    'currency': '',  # string ou vide
                    'pos_name': '',  # string ou vide
                    'merchant_name': ''  # string ou vide
                }
            }
            
            print(send_transaction('preprod',payload,'${hmac}'))`} />,
        },
      
        Java: {
            install:<CopyField sx={{paddingBottom:2}} textArea language='xml' label='Installez le sdk avec : ' value='<dependency>
    <groupId>io.github.killbillsdev</groupId>
    <artifactId>KillBills_sdk</artifactId>
    <version>0.0.1</version>
</dependency>' />,
sendReceipt: <CopyField sx={{paddingBottom:2}} textArea language='java' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={`Sdk.sendReceipt("preprod", data, "${hmac}")`} />,
sendTransaction: <CopyField sx={{paddingBottom:2}} textArea language='java' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={`Sdk.sendReceipt("preprod", data, "${hmac}")`} />,
        },
        Go: {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le sdk avec : ' value='go get github.com/killbillsdev/go_sdk/go_sdk' />,
            sendReceipt: <CopyField sx={{paddingBottom:2}} textArea language='go' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={`var validPayload2 = map[string]interface{}{
                "date": "2023-07-30T09:04:08",
                "mode": "0",
                "items": []map[string]interface{}{
                    {
                        "tax": map[string]interface{}{
                            "rate":        1000,
                            "amount":      85,
                            "description": "TVA",
                        },
                        "name":     "Salade ATCHOUM",
                        "price":    850,
                        "quantity": 1,
                        "subitems": []map[string]interface{}{
                            {
                                "tax": map[string]interface{}{
                                    "rate":        1000,
                                    "amount":      30,
                                    "description": "TVA",
                                },
                                "name":         "Atchoum V1",
                                "price":        1555,
                                "quantity":     1,
                                "description":  "",
                                "reference_id": "5df1e0fa-3bdc-461a-9170-a74bb2f0792b",
                                "total_amount": 300,
                            },
                            {
                                "tax": map[string]interface{}{
                                    "rate":        1000,
                                    "amount":      40,
                                    "description": "TVA",
                                },
                                "name":         "Saucisses v1",
                                "quantity":     1,
                                "description":  "",
                                "reference_id": "d15e20c6-925c-491a-8381-153c9352aadd",
                                "total_amount": 400,
                            },
                            {
                                "tax": map[string]interface{}{
                                    "rate":        1000,
                                    "amount":      25,
                                    "description": "TVA",
                                },
                                "name":         "Thé v1",
                                "quantity":     1,
                                "description":  "",
                                "reference_id": "72b2479f-9210-44fc-8187-a4f40bc31ee6",
                                "total_amount": 250,
                            },
                        },
                        "description":  "",
                        "reference_id": "1c49ad5c-2610-4bd7-bbb5-e235639a0a42",
                        "total_amount": 850,
                    },
                    // ... (other items)
                },
                "store": map[string]interface{}{
                    "store_name":         "RESTAU TEST",
                    "siret":              "66666666666666",
                    "billing_descriptor": "RESTAU TEST",
                    "address": map[string]interface{}{
                        "city":           "Paris",
                        "number":         0,
                        "country":        "FRANCE",
                        "postal_code":    75014,
                        "street_address": "17 rue du Smart Receipt",
                    },
                    "code_ape":     "4410",
                    "tva_intra":    "FR 000 000 00",
                    "reference_id": "1",
                },
                "table": "31",
                "taxes": []map[string]interface{}{
                    {
                        "rate":        1000,
                        "aamount":     85,
                        "description": "TVA",
                    },
                    {
                        "rate":        2000,
                        "amount":      190,
                        "description": "TVA",
                    },
                },
                "amount":   871741,
                "covers":   2,
                "invoice":  1,
                "currency": "EUR",
                "merchant": map[string]interface{}{
                    "merchant_name": "Restaurant test",
                    "reference_id":  "1234",
                },
                "payments": []map[string]interface{}{
                    {
                        "bin":              "0",
                        "amount":           871741,
                        "scheme":           "",
                        "auth_code":        "",
                        "last_four":        "0",
                        "payment_type":     "CB",
                        "transaction_id":   "null",
                        "transaction_date": "2023-07-30T09:04:08",
                    },
                },
                "partner_name": "${partnerName}",
                "reference_id": "1221554511",
            }
            jsonPayload, err := json.Marshal(validPayload2)
            if err != nil {
                t.Errorf("Error marshaling JSON: %v", err)
                return
            }
sdk.SendReceipt("preprod", jsonPayload, "${hmac}")`} />,
            sendTransaction: <CopyField sx={{paddingBottom:2}} textArea language='go' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={`constantTime := time.Date(2023, time.August, 14, 12, 0, 0, 0, time.UTC)
            validTransaction := Transaction{
                Bank_id: "${partnerName}",
              {
                    Siret: "123456789",
                    Amount: 100.0,
                    Payment: Payment{
                        Bin: "123",
                    },
                    Currency:          "EUR",
                    StoreName:         "Store A",
                    CustomerID:        "cust123",
                    MerchantID:        "merchant123",
                    ReferenceID:       "ref123",
                    MerchantName:      "Merchant A",
                    TransactionDate:   constantTime,
                    BillingDescriptor: "Billing Desc",
                },
                CallbackURL:   "callback",
                PartnerName:   "partnername",
                ReceiptFormat: "PDF",
            }
            transactionData, err := json.Marshal(validTransaction)
            if err != nil {
                t.Fatalf("Failed to marshal transaction data: %v", err)
            }
            sdk.SendBankingTransaction("preprod", transactionData,  "${hmac}")`} />,
        },
        'C#': {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le sdk avec : ' value='dotnet add package Killbills_sdk --version 0.0.5' />,
            sendReceipt: <CopyField sx={{paddingBottom:2}} textArea language='csharp' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={` string env = "preprod";
            ReceiptDto receiptData = new()
            {
                ...yourPayloadData
            };
                
            string hmacKey = "${hmac}";
            string result = await sdk.SendReceipt(env, receiptData, hmacKey);
        
            Console.WriteLine($"Résultat: {result}");`} />,
            sendTransaction: <CopyField sx={{paddingBottom:2}} textArea language='csharp' label='Exécutez la méthode comme suit (votre clé HMAC et votre partner_name ont été renseignés pour vous) :' value={` string env = "preprod";
            TransactionDto transactionData = new()
            {
                ...yourPayloadData
            };
                
            string hmacKey = "${hmac}";
            string result = await sdk.SendTransactionAsync(env, transactionData, hmacKey);
        
            Console.WriteLine($"Résultat: {result}");`} />,
        },
    }

    const  SdkSendInstructions = (languageOption:any):any => {
       return  groups?.includes('bank') ? instructions[languageOption].sendTransaction : groups?.includes('pos') ? instructions[languageOption].sendReceipt : <></>
        
    }
    if (integrationOption === 'Intégrer grâce à notre SDK') {
        return (<Grid xs={12} md={12} lg={12}>{SdkSendInstructions(languageOption)}</Grid>)
    
    } else if (integrationOption === 'Intégrer sans notre SDK') {
        return (
            <>
            <Grid item xs={12}>
            <CopyField
                label={
                    groups.includes('bank')
                        ? 'API /transaction'
                        : 'API /receipt'
                }
                value={
                    groups.includes('bank')
                        ? 'https://in.preprod.killbills.dev/transaction'
                        : 'https://in.preprod.killbills.dev/receipt'
                }
            />
        </Grid>
        <Grid item xs={12}>
            <CopyField
                label="Clé HMAC"
                value={hmac}
            />
        </Grid>
        <Grid item xs={12}>
            <CopyField
                label={
                    groups?.includes('bank')
                        ? 'bank_id'
                        : groups?.includes('pos')
                        ? 'partner_name'
                        : 'none'
                }
                value={groups.filter((i:any)=>!['bank','pos'].includes(i))[0]}
            />
        </Grid>
        </>
        )
    }

}