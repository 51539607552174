import { Button, CopyField, Field } from '@killbillsdev/killbills-ui'
import { useUserContext } from '../contexts/NavigationContext'
import { Auth } from 'aws-amplify'
import { useMemo, useState } from 'react'
import { AccountSettings,Divider,ThemeProvider } from '@aws-amplify/ui-react'
import { GridPage } from '../layout/GridPage'
import { GridCenteredRow } from '../layout/GridCenteredRow'
import { PageTitle } from '../layout/Typographies'
import { Backdrop, CircularProgress } from '@mui/material'
import { dividerTheme } from '../styles'




async function updateUserAttributes(att: any,user:any,setIsLoading:any) {
    try {
        await Auth.updateUserAttributes(user, att)
        setIsLoading(false)
    } catch (err) {
        setIsLoading(false)
        console.log(err)
    }
}
export const ProfilePage = () => {
    const { user, groups, hmac, apiKey } = useUserContext()
    const attributes = useMemo(() => user.attributes, [user])
    const [Info, setInfo] = useState(attributes);
    const [isLoading,setIsLoading] = useState(false);
    const partner = useMemo(
        () => (groups || []).filter((g) => !['pos', 'bank'].includes(g))[0],
        [groups]
    )

    const changed = useMemo(() => {
        if (Info?.given_name !== attributes?.given_name) return true
        if (Info?.family_name !== attributes?.family_name) return true
        if (Info?.email !== attributes?.email) return true
        return false
    }, [Info, attributes])

    return (
        <GridPage>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" size={70} />
            </Backdrop>
            <GridCenteredRow>
                <PageTitle>Mon profil</PageTitle>
            </GridCenteredRow>
            <GridCenteredRow paddingTop={10} paddingBottom={1}>
            <ThemeProvider theme={dividerTheme} colorMode="light">
                <Divider style={{borderWidth:6,borderRadius:10,fontWeight:'bold',color:'white'}} label="Informations personnelles"  size='large' />
            </ThemeProvider>
            </GridCenteredRow>
            <GridCenteredRow>
                <Field
                    sx={{ width: '100%', marginBottom: 2 }}
                    type="text"
                    label="Nom"
                    value={Info.family_name !== 'null' ? Info.family_name : ''}
                    onChange={(e: any) =>
                        setInfo((v: any) => ({ ...v, family_name: e }))
                    }
                />
            </GridCenteredRow>
            <GridCenteredRow>
                <Field
                    sx={{ width: '100%', marginBottom: 2 }}
                    type="text"
                    label="Prénom"
                    value={Info.given_name !== 'null' ? Info.given_name : ''}
                    onChange={(e: any) =>
                        setInfo((v: any) => ({ ...v, given_name: e }))
                    }
                />
            </GridCenteredRow>
            <GridCenteredRow>
                <Field
                    sx={{
                        width: '100%',
                        marginBottom: 2,
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white"},
                    }}
                    type="text"
                    disabled
                    label="Email"
                    value={Info.email}
                    onChange={(e: any) =>
                        setInfo((v: any) => ({ ...v, email: e }))
                    }
                />
            </GridCenteredRow>
            <GridCenteredRow>
                <Button
                    label="Sauvegarder les modifications"
                    variant={changed ? 'primary' : 'disabled'}
                    onClick={async () => {
                        try {
                            setIsLoading(true);
                            updateUserAttributes({
                                given_name: Info.given_name,
                                family_name: Info.family_name,
                            },user,setIsLoading)
                        } catch (error) {
                            setIsLoading(false);
                            console.log(error)
                        }
                        //TODO: add alert on success or failure
                    }}
                />
            </GridCenteredRow>
            <GridCenteredRow paddingTop={12} paddingBottom={1}>
            <ThemeProvider theme={dividerTheme} colorMode="light">
                <Divider style={{borderWidth:6,borderRadius:10,fontWeight:'bold'}} label="Changer de mot de passe"  size='large' />
            </ThemeProvider>
            </GridCenteredRow>
            <GridCenteredRow>
                <AccountSettings.ChangePassword
                    onSuccess={(...args) => console.log(args)}
                />
            </GridCenteredRow>
            <GridCenteredRow paddingTop={12} paddingBottom={1}>
            <ThemeProvider theme={dividerTheme} colorMode="light">
                <Divider style={{borderWidth:6,borderRadius:10,fontWeight:'bold'}} label="Informations de l'entreprise"  size='large' />
            </ThemeProvider>
            </GridCenteredRow>
            {hmac && (
                <GridCenteredRow>
                    <CopyField label="Clé HMAC (préprod)" value={hmac} />
                </GridCenteredRow>
            )}
            <GridCenteredRow>
                {partner && <CopyField label={groups?.includes('bank') ? 'Bank-id' : groups?.includes('pos') ?  "Partner-name" : 'null'} value={partner} />}
            </GridCenteredRow>
            <GridCenteredRow>
                {apiKey && <CopyField label="Clé API store (préprod)" value={apiKey} />}
            </GridCenteredRow>
            <GridCenteredRow paddingTop={5}>
                <Button
                    onClick={() => Auth.signOut()}
                    label="Déconnexion"
                    variant="primary"
                    sx={{ background: '#e9a23b' }}
                />
            </GridCenteredRow>
        </GridPage>
    )
}
