import React, { useState } from 'react'

import {
    View,
    Heading,
    useAuthenticator,
    Image,
    Text,
    useTheme,
    Button as Btn,
} from '@aws-amplify/ui-react'
import { Box } from '@mui/material'
import { useUserContext } from '../contexts/NavigationContext'
import { NavbarPartner } from '@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms/Navbar'
import { Outlet } from 'react-router-dom'
import { steps } from '../utils'

// eslint-disable-next-line react-refresh/only-export-components
export const components = {
    //This const is used to customize the login / password / register form of amplify :)
    Header() {
        const { tokens } = useTheme()

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="KillBills logo"
                    src="https://a.storyblok.com/f/149852/x/fa77143555/color-gradient.svg"
                />
            </View>
        )
    },
    Footer() {
        const { tokens } = useTheme()

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy;KillBills {new Date().getFullYear()}
                </Text>
            </View>
        )
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme()
            return (
                <Heading
                    fontFamily="Ubuntu,sans-serif"
                    fontWeight="bold"
                    color="white"
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Se connecter
                </Heading>
            )
        },
        Footer() {
            const { toResetPassword } = useAuthenticator()
            const [ hover,setHover ] = useState(false);
            return (
                <View textAlign="center">
                    <Btn
                        style={{ color: hover ? 'black' : 'white' }}
                        fontWeight="normal"
                        fontFamily="Ubuntu,sans-serif"
                        onClick={toResetPassword}
                        size="large"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        variation="link"
                    >
                        Mot de passe oublié ?
                    </Btn>
                </View>
            )
        },
    },
    ForceNewPassword: {
       
        Header () {
            const { tokens } = useTheme()
                return (
                    <Heading
                        fontFamily="Ubuntu,sans-serif"
                        fontWeight="bold"
                        color="white"
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Changez de mot de passe
                    </Heading>
                )
        }
    },
    ResetPassword: {
       Header () {
           const { tokens } = useTheme()
               return (
                   <Heading
                       fontFamily="Ubuntu,sans-serif"
                       fontWeight="bold"
                       color="white"
                       padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                       level={3}
                   >
                       Mot de passe oublié
                   </Heading>
               )
       }
       },
    ConfirmResetPassword: {
       
            Header () {
                const { tokens } = useTheme()
                    return (
                        <Heading
                            fontFamily="Ubuntu,sans-serif"
                            fontWeight="bold"
                            color="white"
                            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                            level={3}
                        >
                            Changez de mot de passe
                        </Heading>
                    )
            }
    },
    ConfirmVerifyUser: {
       
        Header () {
            const { tokens } = useTheme()
                return (
                    <Heading
                        fontFamily="Ubuntu,sans-serif"
                        fontWeight="bold"
                        color="white"
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Entrez le code reçu par email pour vérifier votre compte
                    </Heading>
                )
        }
}
}

export const MainLayout = React.memo(function MainLayout({
    location,
    groups,
}: any) {
    const { user, navigate, integrationStatus } = useUserContext()
    
    const isStepCompleted = (_group: string, page: string) => {
        if (
            steps[page]?.completedIntegrationStatus?.includes(integrationStatus)
            ) {
                return true
            }
            return false
        }
    return (
        <Box sx={{ backgroundColor: '#120f22', width: '100%' }}>
            <NavbarPartner
                getHmacKeyPage={location?.pathname === '/getHmacKey' || location?.pathname === '/sendFirstReceipt' || location?.pathname === '/sendFirstTransaction' ? true : false}
                breadCrumb={{
                    page: location?.pathname,
                    type: groups?.includes('bank')
                        ? 'bank'
                        : groups?.includes('pos')
                        ? 'pos'
                        : 'null',
                    stepCompleted: isStepCompleted(
                        groups?.includes('bank')
                            ? 'bank'
                            : groups?.includes('pos')
                            ? 'pos'
                            : 'null',
                        location?.pathname
                    ),
                    navigate,
                    integrationStatus:integrationStatus
                }}
                userName={user?.attributes?.email}
                handleProfileClick={() => navigate('/profile')}
                handleDocClick={() =>
                    window
                        ?.open(
                            'https://killbills.readme.io/reference/killbills-api-reference',
                            '_blank'
                        )
                        ?.focus()
                }
            />

            <Outlet />
        </Box>
    )
})
