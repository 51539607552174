import { Grid } from '@mui/material'
export const GridByThree = ({
    childrenLeft,
    childrenCenter,
    childrenRight,
    paddingBottom = 0,
    paddingTop = 0,
}: any) => {
    return (
        <Grid
            container
            item
            xs={12}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
        >
            <Grid item sm={12} xs={12} lg={4} md={4}>
                {childrenLeft}
            </Grid>
            <Grid item sm={12} xs={12} lg={4} md={4}>
                {childrenCenter}
            </Grid>
            <Grid item sm={12} xs={12} lg={4} md={4}>
                {childrenRight}
            </Grid>
        </Grid>
    )
}
