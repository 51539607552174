import { Grid } from '@mui/material'

export const GridCenteredRow = ({
    children,
    paddingBottom = 0,
    paddingTop = 0,
    large = false,
    id
}: any) => {
    return (
        <Grid
            container
            item
            xs={12}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
        >
            <Grid
                item
                sm={large ? 1 : 2}
                xs={large ? 1 : 1}
                lg={large ? 2 : 4}
            ></Grid>
            <Grid
                item
                sm={large ? 10 : 8}
                xs={large ? 10 : 10}
                lg={large ? 8 : 4}
                id={id}
            >
                {children}
            </Grid>
            <Grid
                item
                sm={large ? 1 : 2}
                xs={large ? 1 : 1}
                lg={large ? 2 : 4}
            ></Grid>
        </Grid>
    )
}
