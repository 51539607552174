import { Auth } from "aws-amplify";
import { useIntegrationStatusCheckProps } from "../types";
import { validateIntegrationStatus } from "../utils";

const useIntegrationStatusCheck = ({navigate,location}:useIntegrationStatusCheckProps) => {
       
    const checkSessionsExpiracy = async () => {
        const session = await Auth.currentSession();
        const test = Number(session?.getIdToken().getExpiration() * 1000);
        if (new Date().getTime() > test) { Auth.signOut() }
    };

    // Exécution unique sans useEffect
    const checkStatus = async () => {
        const isValid = await validateIntegrationStatus(location?.pathname);

        if (!isValid?.isOk) {
            await navigate(isValid.shouldRedirectTo);
        }
    };

    checkSessionsExpiracy();
    checkStatus();
};

export default useIntegrationStatusCheck;
