import { GridPage } from '../layout/GridPage'
import { GridCenteredRow } from '../layout/GridCenteredRow'
import { GridByThree } from '../layout/GridByThree'
import { PageTitle } from '../layout/Typographies'
import { SubTitle } from '../layout/Typographies'
import { Card, Button } from '@killbillsdev/killbills-ui'
import fakeGraph from '../layout/fakeGraph.svg'
import FakeGraph1 from '../layout/fakeGraph1.svg'
import FakeGraph2 from '../layout/fakeGraph2.svg'
import FakeGraph3 from '../layout/fakeGraph3.svg'
import FakeGraph4 from '../layout/fakeGraph4.svg'
import FakeGraph5 from '../layout/fakeGraph5.svg'
import FakeGraph6 from '../layout/fakeGraph6.svg'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import { DialogActions } from '@mui/material'
import { useUserContext } from '../contexts/NavigationContext'

const DialogCustom = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: 'white',
    },
    '& .MuiDialogContentText-root': {
        padding: theme.spacing(1),
        color: 'white',
    },
    '& .MuiDialog-paper	': {
        background: 'linear-gradient(180deg, #252234 -2.77%, #131023 100%)',
        color: 'white',
        padding:theme.spacing(3),
    },
}))

interface GrafProps {
    v: number
}

const Graf: React.FC<GrafProps> = ({ v }) => {
    switch (v) {
        case 1:
            return <img src={FakeGraph1} alt="Graph 1" />
        case 2:
            return <img src={FakeGraph2} alt="Graph 2" />
        case 3:
            return <img src={FakeGraph3} alt="Graph 3" />
        case 4:
            return <img src={FakeGraph4} alt="Graph 4" />
        case 5:
            return <img src={FakeGraph5} alt="Graph 5" />
        case 6:
            return <img src={FakeGraph6} alt="Graph 6" />
        case 7:
            return <img src={fakeGraph} alt="Default Graph" />
        default:
            return <img src={fakeGraph} alt="Default Graph" />
    }
}
export const DashboardPage = () => {
    const { navigate } = useUserContext()
    const handleBackToIntro = async () => {
        try {
            navigate('/introduction')
        } catch (error) {
            console.error('Error', error)
        }
    }
    return (
        <GridPage blur>
            <GridCenteredRow>
                <PageTitle>Dashboard</PageTitle>
                <SubTitle>Retrouvez ici vos statistiques d'usage</SubTitle>
            </GridCenteredRow>
            <GridByThree
                childrenLeft={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={1} />
                    </Card>
                }
                childrenCenter={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={2} />
                    </Card>
                }
                childrenRight={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={3} />
                    </Card>
                }
            />
            <GridByThree
                childrenLeft={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={4} />
                    </Card>
                }
                childrenCenter={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={5} />
                    </Card>
                }
                childrenRight={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={6} />
                    </Card>
                }
            />
            <GridByThree
                childrenLeft={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={5} />
                    </Card>
                }
                childrenCenter={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={7} />
                    </Card>
                }
                childrenRight={
                    <Card sx={{ padding: 5, margin: 5 }}>
                        <Graf v={2} />
                    </Card>
                }
            />

            <DialogCustom open={true} disableScrollLock>
                <DialogTitle id="alert-dialog-title">
                    <PageTitle>
                        {'L\'intégration est presque terminée ! 🏁'}
                    </PageTitle>
                </DialogTitle>
                <DialogContent>
                    <SubTitle>
                    KillBills revient vers vous afin de valider l&apos;intégration en preprod et vous donner votre clé HMAC de production ⏳
                    </SubTitle>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ marginTop: 5 }}
                        label="Revenir à l'introduction"
                        variant="primary"
                        onClick={() => handleBackToIntro()}
                    />
                </DialogActions>
            </DialogCustom>
        </GridPage>
    )
}
