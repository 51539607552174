import { Auth } from 'aws-amplify'
import { Pages, nextParameters, validationResult } from './types'

export const pages: Pages = {
    '/': {
        authorizedIntegrationStatus: [],
        authorizedGroups: [],
        shouldRedirectTo: '/',
        allowAll: true,
    },
    '/introduction': {
        authorizedIntegrationStatus: [],
        authorizedGroups: [],
        shouldRedirectTo: '/',
        allowAll: true,
    },
    '/chooseLanguage': {
        authorizedIntegrationStatus: [
            'null',
            'started',
            'storesStepValid',
            'hmacValid',
            'postHmacValid',
            'transactionReceived',
            'receiptReceived',
        ],
        authorizedGroups: ['bank', 'pos'],
        shouldRedirectTo: '/',
        allowAll: false,
    },
    '/startInstallation': {
        authorizedIntegrationStatus: [
            'null',
            'started',
            'storesStepValid',
            'hmacValid',
            'postHmacValid',
            'transactionReceived',
            'receiptReceived',
        ],
        authorizedGroups: ['bank', 'pos'],
        shouldRedirectTo: '/chooseLanguage',
        allowAll: false,
    },
    '/getStoresList': {
        authorizedIntegrationStatus: [
            'started',
            'storesStepValid',
            'hmacValid',
            'postHmacValid',
            'transactionReceived',
        ],
        authorizedGroups: ['bank'],
        shouldRedirectTo: '/startInstallation',
        allowAll: false,
    },
    '/getHmacKey': {
        authorizedIntegrationStatus: [
            'started',
            'storesStepValid',
            'hmacValid',
            'postHmacValid',
            'transactionReceived',
            'receiptReceived',
        ],
        authorizedGroups: ['bank', 'pos'],
        shouldRedirectTo: '/getStoresList',
        allowAll: false,
    },
    '/sendFirstReceipt': {
        authorizedIntegrationStatus: [
            'hmacValid',
            'postHmacValid',
            'receiptReceived',
        ],
        authorizedGroups: ['pos'],
        shouldRedirectTo: '/getHmacKey',
        allowAll: false,
    },
    '/sendFirstTransaction': {
        authorizedIntegrationStatus: [
            'hmacValid',
            'postHmacValid',
            'transactionReceived',
        ],
        authorizedGroups: ['bank'],
        shouldRedirectTo: '/getHmacKey',
        allowAll: false,
    },
    '/firstReceiptReceived': {
        authorizedIntegrationStatus: ['postHmacValid', 'receiptReceived'],
        authorizedGroups: ['pos'],
        shouldRedirectTo: '/sendFirstReceipt',
        allowAll: false,
    },
    '/firstTransactionReceived': {
        authorizedIntegrationStatus: ['postHmacValid', 'transactionReceived'],
        authorizedGroups: ['bank'],
        shouldRedirectTo: '/sendFirstTransaction',
        allowAll: false,
    },
    '/profile': {
        authorizedIntegrationStatus: [],
        authorizedGroups: [],
        shouldRedirectTo: '',
        allowAll: true,
    },
}

export const steps: any = {
    '/getStoresList': {
        completedIntegrationStatus: [
            'storesStepValid',
            'hmacValid',
            'postHmacValid',
            'transactionReceived',
        ],
    },
    '/getHmacKey': {
        completedIntegrationStatus: [
            'hmacValid',
            'postHmacValid',
            'transactionReceived',
            'receiptReceived',
        ],
    },
    '/sendFirstReceipt': {
        completedIntegrationStatus: ['receiptReceived'],
    },
    '/sendFirstTransaction': {
        completedIntegrationStatus: ['transactionReceived', 'postHmacValid'],
    },
    '/firstReceiptReceived': {
        completedIntegrationStatus: ['receiptReceived'],
    },
    '/firstTransactionReceived': {
        completedIntegrationStatus: ['transactionReceived'],
    },
}

export const updateIntegrationStatus = async (
    integrationStatus: string,
    updateUserContext: any
) => {
    try {
        const user = await Auth.currentAuthenticatedUser()
        const result = await Auth.updateUserAttributes(user, {
            'custom:integrationStatus': integrationStatus,
        })
        await updateUserContext({ integrationStatus: integrationStatus })
        return result
    } catch (error) {
        return error
    }
}
export const updateIntegrationOption = async (
    integrationOption: string,
    updateUserContext: any
) => {
    try {
        const user = await Auth.currentAuthenticatedUser()
        const result = await Auth.updateUserAttributes(user, {
            'custom:integrationOption': integrationOption,
        })
        await updateUserContext({ integrationOption: integrationOption })
        return result
    } catch (error) {
        console.log('error', error)
        return error
    }
}
export const updateLanguageOption = async (
    languageOption: string,
    updateUserContext: any
) => {
    try {
        const user = await Auth.currentAuthenticatedUser()
        const result = await Auth.updateUserAttributes(user, {
            'custom:languageOption': languageOption,
        })
        await updateUserContext({ languageOption: languageOption })
        return result
    } catch (error) {
        return error
    }
}

export const updateIsHelpNeeded = async (
    isHelpNeeded: string,
    updateUserContext: any
) => {
    try {
        const user = await Auth.currentAuthenticatedUser()
        const result = await Auth.updateUserAttributes(user, {
            'custom:isHelpNeeded': isHelpNeeded,
        })
        await updateUserContext({ isHelpNeeded: isHelpNeeded })
        return result
    } catch (error) {
        return error
    }
}

export const validateIntegrationStatus = async (
    page: string
): Promise<validationResult> => {
    try {
        const knownPage = (page: string) => Object.keys(pages).includes(page)
        if (!knownPage(page)) return { isOk: true, shouldRedirectTo: page }

        const user = await Auth.currentAuthenticatedUser()
        //const actualUser = await Auth.currentUserInfo()

        const integrationStatus = user?.attributes['custom:integrationStatus']

        const groups =
            user?.signInUserSession.accessToken.payload['cognito:groups']
        const actualPage = pages[page]
        if (actualPage === undefined || !actualPage)
            return { isOk: false, shouldRedirectTo: '/' }
        if (
            page === '/getHmacKey' &&
            groups?.includes('bank') &&
            integrationStatus === 'started'
        )
            return { isOk: false, shouldRedirectTo: '/getStoresList' }
        if (actualPage?.allowAll) return { isOk: true, shouldRedirectTo: null }
        if (
            actualPage?.authorizedGroups?.includes(
                groups?.filter((group: string) =>
                    group === 'pos' ? 'pos' : group === 'bank' ? 'bank' : ''
                )[0]
            ) &&
            actualPage?.authorizedIntegrationStatus?.includes(integrationStatus)
        ) {
            return { isOk: true, shouldRedirectTo: null }
        }
        const shouldRedirectTo = () => {
            if (integrationStatus === 'null') return '/'
            if (actualPage === undefined) return '/notFound'
            if (actualPage?.shouldRedirectTo === '/getStoresList') {
                return groups?.includes('bank')
                    ? '/getStoresList'
                    : '/startInstallation'
            }
            return actualPage?.shouldRedirectTo
        }
        console.log('194', {
            isOk: false,
            shouldRedirectTo: shouldRedirectTo(),
        })

        return { isOk: false, shouldRedirectTo: shouldRedirectTo() }
    } catch (error) {
        console.log('==============error======================')
        console.log(error)
        console.log('====================================')
        return { isOk: false, shouldRedirectTo: '/' }
    }
}

export const next = async ({
    navigate,
    isOkForNextStep,
    integrationStatus,
    requiredIntegrationStatus,
    nextIntegrationStatus,
    nextPage,
    updateUserContext,
}: nextParameters) => {
    if (isOkForNextStep && integrationStatus === requiredIntegrationStatus) {
        await updateIntegrationStatus(nextIntegrationStatus, updateUserContext)
        navigate(nextPage)
    } else {
        navigate(nextPage)
    }
}

export const statusDashboardAuthorized = [
    'transactionReceived',
    'receiptReceived',
    'completed',
]

export function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}