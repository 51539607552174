import { Card } from '@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms'

import { Box,  Grid, Typography } from '@mui/material'

import { PointOfSale } from '@mui/icons-material'
import { GridCenteredRow } from '../layout/GridCenteredRow'
import Line from '../../public/Line.svg';

export const HowItWorks = ({ group }: any) => {
    if (group === 'bank') {
        return (
            <GridCenteredRow container large paddingBottom={5}>
                <Card sx={{ padding: 5  }}>
                    <Typography
                        fontFamily="Ubuntu"
                        textAlign={{ xs: 'center', lg: 'start' }}
                        sx={{
                            whiteSpace: { xs: 'wrap', lg: 'nowrap' },
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            fontSize: {
                                xs: 18,
                                sm: 18,
                                md: 22,
                                lg: 22,
                                xl: 28,
                            },
                            lineHeight: 'normal',
                            paddingBottom: 3,
                        }}
                    > Comment fonctionne KillBills ? 🤔
                    </Typography>
                    <Typography
                        fontFamily="Ubuntu"
                        textAlign={{ xs: 'center', lg: 'start' }}
                        sx={{
                            width: { xs: '100%', lg: '100%' },
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            lineHeight: 'normal',
                            paddingBottom: 2,
                            fontSize:{xl:20,xs:16}
                        }}
    
                    >
                        KillBills est une plateforme permettant aux consommateurs de recevoir leurs tickets de caisse directement dans leur espace bancaire après un paiement par carte.
                    </Typography>
                    <Typography   fontFamily="Ubuntu"
                        textAlign={{ xs: 'center', lg: 'start' }}
                        sx={{
                            width: { xs: '100%', lg: '100%' },
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            lineHeight: 'normal',
                            paddingBottom: 2,
                            fontSize:{xl:20,xs:16}
                        }}
    >
                    En exposant des APIs à ses partenaires, KillBills reçoit des transactions bancaires et des tickets de caisse.
                    </Typography>
                    <Typography   textAlign={{ xs: 'center', lg: 'start' }} fontFamily="Ubuntu"
                        sx={{
                            width: { xs: '100%', lg: '100%' },
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            lineHeight: 'normal',
                            paddingBottom: 5,
                            fontSize:{xl:20,xs:16}
                        }}>
                    L’algorithme analyse les jeux de données reçus et les réconcilie lorsqu’un achat est identifié. Il ne reste plus qu’à renvoyer l’information du ticket de caisse à la banque pour que celle-ci la mette à disposition du consommateur ! 🧾 📲
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="space-around"
                        display={'flex'}
                    >
                        <Grid item xs={12} md={3} lg={3}>
                            <Card
                                sx={{
                                    padding: 5,
                                    width: '100%',
                                    height: '100%',
                                }}
                                shadow="white"
                            >
                                <Typography
                                    fontSize={{
                                        xs: 10,
                                        sm: 10,
                                        md: 16,
                                        lg: 18,
                                        xl: 20,
                                    }}
                                    fontFamily="Ubuntu"
                                    sx={{
                                        alignItems: 'center',
                                        width: '100%', 
                                        textAlign: 'center', 
                                        marginBottom:'10px'
                                    }}
                                    color="#fff"
                                >
                                    🏦{' '}
                                    Banque
                                </Typography>
                                <Typography
                                    fontSize={{
                                        xs: 10,
                                        sm: 10,
                                        md: 10,
                                        lg: 14,
                                        xl: 16,
                                    }}
                                    fontFamily="Ubuntu"
                                    variant="body1"
                                    color="white"
                                >
                                    A la demande d'autorisation d'un paiement, la banque envoie à KillBills le détail de la transaction bancaire.
                                </Typography>
                                <Typography
                                    fontSize={{
                                        xs: 10,
                                        sm: 10,
                                        md: 10,
                                        lg: 14,
                                        xl: 16,
                                    }}
                                    fontFamily="Ubuntu"
                                    variant="subtitle2"
                                    color="gray"
                                >
                                    Il est important d’envoyer les informations du point de vente concerné, comme mentionné dans la documentation technique.
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={Line} width={100} height={100} alt='line-1' />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Card
                                sx={{
                                    padding: 5,
                                    width: '100%',
                                    height: '100%',
                                }}
                                shadow="white"
                            >
                                <Typography
                                    fontSize={{
                                        xs: 'auto',
                                        sm: 10,
                                        md: 16,
                                        lg: 18,
                                        xl: 20,
                                    }}
                                    fontFamily="Ubuntu"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent:'center',
                                        width: '100%', 
                                        textAlign: 'center', 
                                        marginBottom:'10px'
                                    }}
                                    color="#fff"
                                >
                                    <Box sx={{ paddingRight: 1,paddingTop:1 }}>
                                        <img
                                            width={20}
                                            height={20}
                                            alt="logo"
                                            src="kbmarker.ico"
                                        />
                                    </Box>{' '}
                                    KillBills
                                </Typography>
                                <Typography
                                    fontSize={{
                                        xs: 10,
                                        sm: 10,
                                        md: 10,
                                        lg: 14,
                                        xl: 16,
                                    }}
                                    fontFamily="Ubuntu"
                                    variant="body1"
                                    color="white"
                                >
                                    KillBills enregistre l’information de la transaction bancaire.
                                </Typography>
                                <Typography
                                    fontSize={{
                                        xs: 10,
                                        sm: 10,
                                        md: 10,
                                        lg: 14,
                                        xl: 16,
                                    }}
                                    fontFamily="Ubuntu"
                                    variant="subtitle2"
                                    color="gray"
                                >
                                    L’algorithme recherche le ticket associé à la transaction parmi ceux reçus des marchands partenaires.
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={Line} width={100} height={100} alt='line-2' />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Card
                                sx={{
                                    padding: 5,
                                    width: '100%',
                                    height: '100%',
                                }}
                                shadow="green"
                            >
                                <Typography
                                    fontSize={{
                                        xs: 10,
                                        sm: 10,
                                        md: 16,
                                        lg: 18,
                                        xl: 20,
                                    }}
                                    fontFamily="Ubuntu"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent:'center',
                                        width: '100%', 
                                        textAlign: 'center', 
                                        marginBottom:'10px'
                                    }}
                                    color="#fff"
                                >
                                    🏦{' '}
                                    Banque
                                </Typography>
                                <Typography
                                    fontSize={{
                                        xs: 10,
                                        sm: 10,
                                        md: 10,
                                        lg: 14,
                                        xl: 16,
                                    }}
                                    fontFamily="Ubuntu"
                                    variant="body1"
                                    color="white"
                                >
                                    La banque reçoit de KillBills le détail du ticket de caisse !
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
                </Card>
            </GridCenteredRow>
        )
    } else if (group === 'pos') {
        return (
            <GridCenteredRow container large>
                <Card sx={{ padding: 5 }}>
                    <Typography
                        fontFamily="Ubuntu"
                        textAlign={'start'}
                        sx={{
                            whiteSpace: 'nowrap',
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            fontSize: {
                                xs: 18,
                                sm: 18,
                                md: 22,
                                lg: 22,
                                xl: 28,
                            },
                            lineHeight: 'normal',
                            paddingBottom: 2,
                        }}
                    >
                        Comment fonctionne KillBills ? 🤔
                    </Typography>
                    <Typography
                        fontFamily="Ubuntu"
                        textAlign={{ xs: 'center', lg: 'start' }}
                        sx={{
                            width: { xs: '100%', lg: '100%' },
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            lineHeight: 'normal',
                            paddingBottom: 2,
                            fontSize:{xl:20,xs:16}
                        }}
    
                    >
                        KillBills est une plateforme permettant aux consommateurs de recevoir leurs tickets de caisse directement dans leur espace bancaire après un paiement par carte.
                    </Typography>
                    <Typography   fontFamily="Ubuntu"
                        textAlign={{ xs: 'center', lg: 'start' }}
                        sx={{
                            width: { xs: '100%', lg: '100%' },
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            lineHeight: 'normal',
                            paddingBottom: 2,
                            fontSize:{xl:20,xs:16}
                        }}
    >
                    En exposant des APIs à ses partenaires, KillBills reçoit des transactions bancaires et des tickets de caisse.
                    </Typography>
                    <Typography   textAlign={{ xs: 'center', lg: 'start' }} fontFamily="Ubuntu"
                        sx={{
                            width: { xs: '100%', lg: '100%' },
                            background:
                                'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: '#fff',
                            MozBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: '#FFF',
                            lineHeight: 'normal',
                            paddingBottom: 5,
                            fontSize:{xl:20,xs:16}
                        }}>
                    L’algorithme analyse les jeux de données reçus et les réconcilie lorsqu’un achat est identifié. Il ne reste plus qu’à renvoyer l’information du ticket de caisse à la banque pour que celle-ci la mette à disposition du consommateur ! 🧾 📲
                    </Typography>
                    <Grid container
                        spacing={2}
                        justifyContent="space-between"
                        display={'flex'}>
                    <Grid item xs={12} md={5} lg={5} xl={5}>
                        <Card
                            sx={{
                                padding: 5,
                                width: '100%',
                                height: '100%',
                            }}
                            shadow="white"
                        >
                            <Typography
                                fontFamily="Ubuntu"
                                sx={{ display: 'flex', alignItems: 'center', justifyContent:'center',
                                width: '100%', 
                                textAlign: 'center', 
                                marginBottom:'10px' }}
                                color="#fff"
                                fontSize={20}
                            >
                                <PointOfSale sx={{ paddingRight: 1,width:30,height:30 }} /> POS
                            </Typography>
                            <Typography
                                fontFamily="Ubuntu"
                                variant="body1"
                                color="white"
                                fontSize={{
                                    xs: 10,
                                    sm: 10,
                                    md: 10,
                                    lg: 14,
                                    xl: 16,
                                }}
                            >
                                A la clôture d’une commande, le POS envoie à KillBills le détail du ticket de caisse
                            </Typography>
                            <Typography
                                fontFamily="Ubuntu"
                                variant="subtitle2"
                                color="gray"
                                fontSize={{
                                    xs: 10,
                                    sm: 10,
                                    md: 10,
                                    lg: 14,
                                    xl: 16,
                                }}
                            >
                                Il est important d’envoyer les informations du point de vente concerné, comme mentionné dans la documentation technique.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} item xs={12} md={2} lg={2} xl={1}>
                        <img src={Line} width={100} height={100} alt='line-2' />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5} xl={6}>
                    <Card
                             sx={{
                                padding: 5,
                                width: '100%',
                                height: '100%',
                            }}
                            shadow="green"
                        >
                            <Typography
                                fontFamily="Ubuntu"
                                sx={{ display: 'flex', alignItems: 'center',justifyContent:'center',
                                width: '100%', 
                                textAlign: 'center', 
                                marginBottom:'10px' }}
                                color="#fff"
                                fontSize={20}
                            >
                                <Box sx={{ paddingRight: 1,paddingTop:1 }}>
                                    <img
                                        width={20}
                                        height={20}
                                        alt="logo"
                                        src="kbmarker.ico"
                                    />
                                </Box>{' '}
                                KillBills
                            </Typography>
                            <Typography
                                fontFamily="Ubuntu"
                                variant="body1"
                                color="white"
                                fontSize={{
                                    xs: 10,
                                    sm: 10,
                                    md: 10,
                                    lg: 14,
                                    xl: 16,
                                }}
                            >
                                KillBills enregistre l’information et génère un ticket de caisse
                            </Typography>
                            <Typography
                                fontFamily="Ubuntu"
                                variant="subtitle2"
                                color="gray"
                                fontSize={{
                                    xs: 10,
                                    sm: 10,
                                    md: 10,
                                    lg: 14,
                                    xl: 16,
                                }}
                            >
                                L’algorithme analyse les transactions bancaires reçues des partenaires bancaires et envoie le ticket à la banque en cas de match.
                            </Typography>
                        </Card> 
                    </Grid>
                       
                    </Grid>
                </Card>
            </GridCenteredRow>
        )
    } else {
        return <></>
    }
}
