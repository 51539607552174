import { Backdrop, CircularProgress, Grid } from '@mui/material'
import { next } from '../utils'
import { useState } from 'react'
import { useUserContext } from '../contexts/NavigationContext'
import { GridPage } from '../layout/GridPage'
import { GridPanelRow } from '../layout/GridPanelRow'
import { PageTitle, SubTitle } from '../layout/Typographies'
import {
    Card,
    Button,
    HmacKeyValid,
    Alert,
} from '@killbillsdev/killbills-ui'
import { GetHmacKey } from '../molecules';
import bank from '../../public/bank.png';
import pos from '../../public/pos.png';

import { API } from 'aws-amplify'

export const GetHmacKeyPage = () => {
    
    const { navigate, groups, integrationStatus, hmac, updateUserContext, companyLogo } = useUserContext()
    const [ isLoading, setIsLoading ] = useState(false);
    const [partnerPayloadReceived, setPartnerPayloadReceived] = useState(false);
    const [ isAlertVisible,setIsAlertVisible ] = useState(false);
    
    const statusRequired = groups?.includes('bank') ? 'storesStepValid' : 'started'
    const nextPage = groups?.includes('bank') ? '/sendFirstTransaction' : '/sendFirstReceipt'

    const hmacStepCompleted = async () => {
        try {
            const r = (await API.graphql({
                query: `
                    query hmacStepCompleted {
                        hmacStepCompleted {
                            pass
                        }
                    }
                `,
            })) as any

            setPartnerPayloadReceived(r.data.hmacStepCompleted.pass)
            !r.data.hmacStepCompleted.pass &&
                setIsAlertVisible(true);
                window.scrollTo(0,0);
                setTimeout(() => {
                    setIsAlertVisible(false);
                },8000);
                setIsLoading(false)

        } catch (e: any) {
            console.log(e.errors)
            setPartnerPayloadReceived(false)
            setIsLoading(false)
        }
    }
    return (
        <GridPage>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" size={70} />
            </Backdrop>
            <GridPanelRow
                leftChildren={
                    <Grid container item xs={12} md={10}>
                        {isAlertVisible ? <Alert sx={{marginBottom:5}} type='error' label='HMAC non reconnu par KillBills' /> : ''}
                        {partnerPayloadReceived ? (
                            <Grid item>
                                <PageTitle paddingBottom={2}>Clé HMAC validée 🎉</PageTitle>
                                <SubTitle>
                                    La connexion est établie ! Vous êtes maintenant en mesure d'envoyer des {groups?.includes('bank') ? 'transaations' : groups?.includes('pos') ? 'tickets de caisse ' : undefined} à la plateforme KillBills.
                                </SubTitle>
                            </Grid>
                        ) : (
                            <Grid item>
                                <PageTitle paddingBottom={2}>
                                Validez la clé HMAC 🔐
                                </PageTitle>
                                <SubTitle sx={{marginBottom:1}}>
                                    Le but de cette étape est d’envoyer une requête à KillBills afin de valider la clé HMAC.
                                </SubTitle>
                                <SubTitle sx={{marginTop:1}}>
                                La documentation technique détaille la manière dont celle-ci doit être utilisée. Seuls la clé HMAC et le champs partner_name sont analysés à cette étape.
                                    Ces informations sont accessibles depuis votre profil. {'\n'} 
                                    Vous devez obtenir un code 200 en retour de cette requête.
                                </SubTitle>
                            </Grid>
                        )}
                        <Grid container xs={10} gap={4} marginTop={4}>
                            {!partnerPayloadReceived && (
                                GetHmacKey({groups,hmac})
                            )}
                            <Grid item xs={12}>
                                <Button
                                    variant="primary"
                                    label={
                                        partnerPayloadReceived !== true
                                            ? "J'ai reçu un code 200"
                                            : "Continuer l'intégration"
                                    }
                                    onClick={() => {
                                        setIsLoading(true);
                                        partnerPayloadReceived !== true
                                            ? 
                                                    hmacStepCompleted()
                                            : 
                                                next({
                                                  navigate,
                                                  isOkForNextStep:
                                                      partnerPayloadReceived,
                                                  integrationStatus,
                                                  requiredIntegrationStatus:
                                                      statusRequired,
                                                  nextIntegrationStatus:
                                                      'hmacValid',
                                                  nextPage: nextPage,
                                                  updateUserContext,
                                                })
                                        
                                    }
                                    
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                rightChildren={
                    <Card
                        timeLine
                        sx={{
                            width: '40vw',
                            height: '100%',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'flex',
                                lg: 'flex',
                                xl: 'flex',
                            },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <HmacKeyValid
                            partnerIcon={companyLogo  ||(groups?.includes('bank') ? bank : groups?.includes('pos') ? pos : '' )}
                            skeleton={partnerPayloadReceived ? false : true}
                            sx={{
                                width: '40%',
                                height: partnerPayloadReceived ? '10%' : '20%',
                            }}
                        />
                    </Card>
                }
            />
        </GridPage>
    )
}
