import { withAuthenticator } from '@aws-amplify/ui-react';
import { translations } from '@aws-amplify/ui-react';
import { MainRoutes } from './routes/MainRoutes';
import { BrowserRouter } from 'react-router-dom';
import { components } from './layout/MainLayout';
import { Amplify, API,I18n } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { AppTypes } from './types';
import '@fontsource/ubuntu';
import './App.css';

I18n.putVocabularies(translations)
I18n.setLanguage('fr')

I18n.putVocabularies({
    fr: {
        'Sign In': 'Se connecter',
        'Sign Up': "S'inscrire",
        'Enter your Password': 'Saisissez votre mot de passe',
        'Current Password': 'Mot de passe actuel',
        'Update password': 'Mettre à jour le mot de passe',
    },
    es: {
        'Sign In': 'Registrarse',
        'Sign Up': 'Regístrate',
    },
})


Amplify.configure(awsExports);
// hope it will work

API.configure({
    aws_appsync_graphqlEndpoint: import.meta.env.VITE_REACT_APP_APPSYNC_API_ENDPOINT!,
    aws_appsync_region: 'eu-west-3',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: '',
})

function App({ signOut, user }: AppTypes) {
    return (
        <BrowserRouter>
            <MainRoutes signOut={signOut} user={user} />
        </BrowserRouter>
    )
}

// eslint-disable-next-line react-refresh/only-export-components
export default withAuthenticator(App, { hideSignUp: true, components })
