import { useState } from 'react'
import {
    updateIntegrationStatus,
    updateLanguageOption,
    updateIntegrationOption,
} from '../utils'
import { useUserContext } from '../contexts/NavigationContext'
import { SelectButton, SelectBox, Button, Field } from '@killbillsdev/killbills-ui'
import { GridCenteredRow } from '../layout/GridCenteredRow'
import { PageTitle, SubTitle } from '../layout/Typographies'
import { GridPage } from '../layout/GridPage'
import { styles } from '../styles'
import { CircularProgress } from '@mui/material'
import { Backdrop } from '@mui/material'

const sdkOptions = [
    { title: 'C#', id: 0 },
    { title: 'Go', id: 1 },
    { title: 'Java', id: 2 },
    { title: 'Python', id: 3 },
    { title: 'NodeJS', id: 4 },
    { title: 'Autre', id: 5 },
]
const integrationOptions = [
    {
        title: 'Intégrer grâce à notre SDK',
        subHeading1: 'Intégrez plus facilement KillBills grâce à notre SDK',
        subHeading2: 'disponible en C#, Go, Java, Node.JS, Python',
        id: 0,
    },
    {
        title: 'Intégrer sans notre SDK',
        subHeading1: 'Intégrez manuellement KillBills',
        id: 1,
    },
]

export const ChooseLanguagePage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {
        navigate,
        groups,
        integrationStatus,
        languageOption,
        integrationOption,
        updateUserContext,
    } = useUserContext()
    const [selectedIntegrationOption, setSelectedIntegrationOption] = useState(
        integrationOptions.find((option) => option.title === integrationOption)?.id ?? -1
    )
    const [otherLang, setOtherLang] = useState('')
    const [selectedSdkOption, setSelectedSdkOption] = useState(
        sdkOptions.find((option) => option.title === languageOption)?.id ?? -1
    )
    const startInstallation = async () => {
        await updateLanguageOption(
            selectedSdkOption ===5 ?`Other:${otherLang}` :sdkOptions[selectedSdkOption]?.title ,
            updateUserContext
        )
        await updateIntegrationOption(
            selectedSdkOption ===5 ? integrationOptions[1]?.title : integrationOptions[selectedIntegrationOption]?.title,
            updateUserContext
        )
        if (integrationStatus === 'null') {
            await updateIntegrationStatus('started', updateUserContext)
            await updateLanguageOption(
                sdkOptions[selectedSdkOption]?.title,
                updateUserContext
            )
            await updateIntegrationOption(
                integrationOptions[selectedIntegrationOption]?.title,
                updateUserContext
            )
        }
        if (groups?.includes('bank')) {
            return navigate('getStoresList')
        } else if (groups?.includes('pos')) {
            return navigate('getHmacKey')
        }
    }

    const active = () => {
        if (selectedIntegrationOption === -1 && selectedSdkOption === -1) return false;
        if (selectedIntegrationOption === 1 && selectedSdkOption === -1) return true;
        if (selectedIntegrationOption === 0 && selectedSdkOption === -1) return false;
        return true;
    }
    return (
        <GridPage>
            <GridCenteredRow>
                <PageTitle>Choix du langage 💻 </PageTitle>
            </GridCenteredRow>
            <GridCenteredRow>
                <SubTitle>Comment souhaitez-vous intégrer l’API de KillBills ? <span style={{color:'red'}}>*</span></SubTitle>
                <SelectBox
                    selectHandler={(_: any, idx: any) =>
                        setSelectedIntegrationOption(idx)
                    }
                    selectedIdx={selectedIntegrationOption}
                    options={integrationOptions}
                />
            </GridCenteredRow>

            <GridCenteredRow>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" size={70} />
                </Backdrop>
                <SubTitle>Quel langage utilisez-vous ? <span style={{color:'red'}}>*</span></SubTitle>
                <SelectButton
                    selectHandler={(_: any, idx: any) =>
                        setSelectedSdkOption(idx)
                    }
                    selectedIdx={selectedSdkOption}
                    options={sdkOptions}
                />
                {selectedSdkOption === 5&&<Field
                    sx={{
                        width: '100%',
                        marginBottom: 2,
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white"},
                    }}
                    type="text"
                    label=""
                    value={otherLang}
                    onChange={(e: any) =>
                        setOtherLang(e)
                    }
                />}
            </GridCenteredRow>

            <GridCenteredRow paddingTop={8}>
                <Button
                    sx={styles.chooseLanguageButton}
                    label={'Valider'}
                    variant={active() ? 'active' : 'disabled'}
                    onClick={() => {
                        startInstallation()
                        setIsLoading(true)
                    }}
                />
            </GridCenteredRow>
        </GridPage>
    )
}
