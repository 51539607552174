import { Grid } from '@mui/material'
export const GridPage = ({ children, haloTop, haloDown, blur }: any) => {
    return (
        <Grid
            container
            sx={
                haloTop
                    ? {
                          background:
                              'radial-gradient(circle at 50% -40%, transparent, rgba(48, 246, 152, 0.3) 1%, transparent 55%, transparent 5%)',
                      }
                    : haloDown
                    ? {
                          background:
                              'radial-gradient(circle at 50% 120%, transparent, rgba(48, 246, 152, 0.3) 1%, transparent 55%, transparent 5%)',
                      }
                    : blur
                    ? { filter: 'blur(2px)' }
                    : {
                          background:
                              'linear-gradient(180deg, #0A0813 0%, #120F22 23.59%)',
                      }
            }
            minWidth={'100%'}
            minHeight={100 - (100 * 76) / window.innerHeight + 'vh'} // necessary because of the trueNavbar px heigh grrr!
            gap={1}
            padding={7}
        >
            {children}
        </Grid>
    )
}
