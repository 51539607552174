import { useState } from 'react'
import { updateIntegrationStatus } from '../utils'
import { useUserContext } from '../contexts/NavigationContext'
import { GridPage } from '../layout/GridPage'
import { PageTitle, SubTitle } from '../layout/Typographies'
import { GridPanelRow } from '../layout/GridPanelRow'
import { Card, Button } from '@killbillsdev/killbills-ui'
import { Grid } from '@mui/material'

export const FirstReceiptReceivedPage = () => {
    const { updateUserContext,navigate } = useUserContext()
    const [callbackReceived, setCallbackReceived] = useState(false)
    const completeStagingIntegration = async () => {
        if (!callbackReceived) {
            setCallbackReceived(true)
            await updateIntegrationStatus('receiptReceived', updateUserContext)
        }
    }
    return (
        <GridPage>
            <GridPanelRow
                leftChildren={
                    <Grid container xs={12} md={12}>
                        <PageTitle paddingBottom={2}>
                            Accédez à la production 👆
                        </PageTitle>
                        <SubTitle>
                        L’équipe KillBills va revenir vers vous rapidement avec les différentes informations pour accéder à la production.
                        </SubTitle>
                        <Grid item xs={12} paddingRight={4} paddingTop={2}>
                            <Button
                                variant="primary"
                                label="Passer à la production"
                                onClick={() =>
                                    callbackReceived !== true
                                        ? completeStagingIntegration()
                                        : navigate('/')
                                }
                            />
                        </Grid>
                    </Grid>
                }
                rightChildren={
                    <Card
                        timeLine
                        sx={{
                            width: '40vw',
                            height: '100%',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'flex',
                                lg: 'flex',
                                xl: 'flex',
                            },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid
                            style={{
                                fontSize: '7em',
                                zIndex: 2,
                            }}
                        >
                            &#10024;
                        </Grid>
                    </Card>
                }
            />
        </GridPage>
    )
}
