import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { MainLayout } from '../layout/MainLayout'
import * as Page from '../pages'
import { statusDashboardAuthorized } from '../utils'
import UserContext from '../contexts/NavigationContext'
import { UserContextType } from '../types'
import React, { useEffect, useMemo, useState } from 'react'
import useIntegrationStatusCheck from '../middlewares/IntegrationStatusMiddleware'
import { Auth } from 'aws-amplify'

export const MainRoutes = React.memo(({ signOut, user }: any) => {
    const [integrationStatus, setIntegrationStatus] = useState('null')
    const [integrationOption, setIntegrationOption] = useState('null')
    const [languageOption, setlanguageOption] = useState('null')
    const [companyLogo, setCompanyLogo] = useState('null')
    const [apiKey, setApikey] = useState('null')
    const [hmac, setHmac] = useState('null')
    const [isHelpNeeded, setIsHelpNeeded] = useState('false');

    useEffect(() => {
        const fetchActualUser = async () => {
            const actualUser = await Auth.currentUserInfo()
            setIntegrationStatus(
                actualUser?.attributes['custom:integrationStatus']
            )
            setIntegrationOption(
                actualUser?.attributes['custom:integrationOption']
            )
            setCompanyLogo(actualUser?.attributes['custom:companyLogo'])
            setlanguageOption(actualUser?.attributes['custom:languageOption'])
            setHmac(actualUser?.attributes['custom:hmac'])
            setApikey(actualUser?.attributes['custom:apikey'])
            setIsHelpNeeded(actualUser?.attributes['custom:isHelpNeeded'])
        }
        fetchActualUser()
    }, [integrationStatus, setIntegrationStatus])

    const updateUserContext = (updatedValues: Partial<UserContextType>) => {
        if (updatedValues?.integrationStatus) {
            setIntegrationStatus(updatedValues?.integrationStatus)
        }
        if (updatedValues?.integrationOption) {
            setIntegrationOption(updatedValues?.integrationOption)
        }
        if (updatedValues?.languageOption) {
            setlanguageOption(updatedValues?.languageOption)
        }
        if (updatedValues?.hmac) {
            setHmac(updatedValues?.hmac)
        }
        if (updatedValues?.isHelpNeeded) {
            setIsHelpNeeded(updatedValues?.isHelpNeeded)
        }
        // Ajoutez d'autres mises à jour si nécessaire
    }
    const groups =
        user?.signInUserSession?.accessToken?.payload['cognito:groups']
    const navigate = useNavigate()
    const location = useLocation()

    useIntegrationStatusCheck({ navigate, integrationStatus, groups, location })

    const renderRoute = (path: string, Component: any, index = false) =>
        index ? (
            <Route key={path} index element={<Component />} />
        ) : (
            <Route key={path} path={path} element={<Component />} />
        )

    const allRoutes = useMemo(() => {
        const standardRoutes = [
            renderRoute(
                '',
                statusDashboardAuthorized?.includes(integrationStatus)
                    ? Page.DashboardPage
                    : Page.IntroductionPage,
                true
            ),
            renderRoute('introduction', Page.IntroductionPage, false),
            renderRoute('chooseLanguage', Page.ChooseLanguagePage, false),
            renderRoute('getHmacKey', Page.GetHmacKeyPage, false),
            renderRoute('profile', Page.ProfilePage, false),
        ]

        const groupSpecificRoutes = groups?.includes('bank')
            ? [
                  renderRoute('getStoresList', Page.GetStoresListPage, false),
                  renderRoute(
                      'sendFirstTransaction',
                      Page.SendFirstTransactionPage,
                      false
                  ),
                  renderRoute(
                      'firstTransactionReceived',
                      Page.FirstTransactionReceivedPage,
                      false
                  ),
              ]
            : groups?.includes('pos')
            ? [
                  renderRoute(
                      'sendFirstReceipt',
                      Page.SendFirstReceiptPage,
                      false
                  ),
                  renderRoute(
                      'firstReceiptReceived',
                      Page.FirstReceiptReceivedPage,
                      false
                  ),
              ]
            : []
        const notFoundRoute = renderRoute('*', Page.NotFoundPage, false)

        return [...standardRoutes, ...groupSpecificRoutes,notFoundRoute]
    }, [groups, integrationStatus])
    return (
        <UserContext.Provider
            value={{
                user,
                groups,
                integrationStatus,
                integrationOption,
                languageOption,
                companyLogo,
                hmac,
                apiKey,
                navigate,
                signOut,
                location,
                updateUserContext,
                isHelpNeeded
            }}
        >
            <Routes>
                <Route path="/" element={<MainLayout location={location} groups={groups} />}>
                    {...allRoutes}
                </Route>
            </Routes>
        </UserContext.Provider>
    )
})
