import { Button } from "@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms";
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../contexts/NavigationContext";
import { GridCenteredRow } from "../layout/GridCenteredRow";
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { pages } from "../utils";
export const ButtonResumeInstallation = () => {
    const navigate = useNavigate();
    const { groups, integrationStatus, updateUserContext } = useUserContext()
    const stepToResume = useMemo(() => {
        for (const page in pages) {
            const pageDetails = pages[page];
            if (
              pageDetails.authorizedIntegrationStatus[0]== integrationStatus && pageDetails.authorizedGroups.includes(groups.includes('bank') ? 'bank' :('pos'))
            ) {
              return page;
            }
          }
          return '/chooseLanguage'
    }, [])
    
    return (
        <GridCenteredRow large paddingTop={5} paddingBottom={5}>
        <Grid container spacing={12} >
                <Grid item xs={6}>
                <Button 
                    variant='primary'
                    label="Reprendre l'intégration"
                    onClick={() => navigate(stepToResume)} 
                    
                />
                </Grid>
                <Grid item xs={6}>
                <Button 
                    variant='secondary'
                    label="Redémarrer l'intégration"
                    onClick={async () => {
                      await updateUserContext({integrationStatus:'null'})
                      await navigate('/chooseLanguage')
                    }} 
                />
                </Grid>
            
        </Grid>
        </GridCenteredRow>
    )

}