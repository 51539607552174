/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_mobile_analytics_app_id": "a0abafef9e7d4e4bab70e17ab043b085",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a0abafef9e7d4e4bab70e17ab043b085",
            "region": "eu-west-1"
        }
    },
    "aws_cognito_identity_pool_id": "eu-west-3:0f314fde-b062-4d84-a912-9a65da9547ce",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_coVWC9IT0",
    "aws_user_pools_web_client_id": "68lbevam300scsvu9s9os63595",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
