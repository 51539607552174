import { Avatar, Grid, Typography } from '@mui/material';

const MyRow = ({ number, text1, text2 }: any) => {
    return (
        <Grid item container xs={12} alignItems={'center'}>
                <Avatar sx={{backgroundColor:'#363247'}}> <Typography color='rgb(123,224,197)' fontFamily="Ubuntu" variant="body1">{number}</Typography></Avatar>
            <Grid item xs={9} md={10} lg={11} textAlign={'left'} sx={{marginLeft:'20px'}}>
                <Typography fontFamily="Ubuntu" variant="body1" color="white" sx={{fontSize: {xs:10,sm:10,md:14,lg:16,xl:20}}}>
                    {text1}
                </Typography>
                <Typography
                    fontFamily="Ubuntu"
                    variant="subtitle2"
                    color="gray"
                >
                    {text2}
                </Typography>
            </Grid>
        </Grid>
    )
}

export const PosSteps = () => {
    return (
        <Grid container gap={2}>
            <MyRow
                number={1}
                text1={'Choix du langage 💻'}
                text2={''}
            />
            <MyRow
                number={2}
                text1={'Validez la clé HMAC 🔐'}
                text2={''}
            />
            <MyRow
                number={3}
                text1={'Envoyez un premier ticket 🧾'}
                text2={''}
            />
            <MyRow
                number={4}
                text1={'Accédez à la production 👆'}
                text2={''}
            />
        </Grid>)
}