import { useUserContext } from '../contexts/NavigationContext'
import { HowItWorks } from '../molecules/HowItWorks'
import { InstallationSteps } from '../molecules/InstallationSteps'
import { ButtonStartInstallation } from '../molecules/ButtonStartInstallation'
import { HeadingTitle } from '../molecules/HeadingTitle'
import { HeadingSubtitle } from '../molecules/HeadingSubtitle'
import { GridPage } from '../layout/GridPage'
import { useMemo } from 'react';
import { ButtonResumeInstallation } from '../molecules/ButtonResumeInstallation'

export const IntroductionPage = () => {
    const { groups, integrationStatus } = useUserContext()
    const group = groups?.includes('bank')
        ? 'bank'
        : groups?.includes('pos')
        ? 'pos'
        : 'none'
    const shouldResumeInstallation = useMemo(() => {
        if(integrationStatus.includes('Received')) return false
        if ( integrationStatus=='null') return false
        return true 
    }, [integrationStatus])
    return (
        <GridPage haloTop>
            <HeadingTitle />
            <HeadingSubtitle />
            <HowItWorks group={group} />
            <InstallationSteps group={group} />
            {shouldResumeInstallation  ? <ButtonResumeInstallation/> : <ButtonStartInstallation />  }
        </GridPage>
    )
}
