import React from 'react'
import { Typography } from '@mui/material'

export interface PageTitleProps {
    children: React.ReactNode
    paddingBottom?: number
    paddingTop?: number
    sx?:any
}

export const PageTitle = ({
    children,
    paddingBottom = 0,
    paddingTop = 0,
}: PageTitleProps) => {
    return (
        <Typography
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
            variant="h4"
            fontWeight="bold"
            fontFamily="Ubuntu"
            color="white"
        >
            {children}
        </Typography>
    )
}

export const SubTitle = ({ children,sx }: PageTitleProps) => {
    return (
        <Typography
            sx={{ marginTop: 1, ...sx }}
            variant="subtitle1"
            color="white"
            gutterBottom
            fontFamily={"Ubuntu"}
        >
            {children}
        </Typography>
    )
}
