import { next } from '../utils'
import { useState, useEffect, useMemo } from 'react'
import { useUserContext } from '../contexts/NavigationContext'
import {
    Button,
    Card,
    TransactionReceived,
    JsonDisplay,
    Tabs,
    Payload,
    Alert,
} from '@killbillsdev/killbills-ui'
import { GridPanelRow } from '../layout/GridPanelRow'
import { PageTitle, SubTitle } from '../layout/Typographies'

import { API } from 'aws-amplify'

import { Backdrop, Box, CircularProgress, Grid, Chip } from '@mui/material'
import { GridPage } from '../layout/GridPage'
// import store from '../../public/store.png';

export const SendFirstTransactionPage = () => {
    const { navigate, groups, integrationStatus, updateUserContext,user } =
        useUserContext()
    const [firstTransactionReceived, setFirstTransactionReceived] =
        useState(false)
    const [showTestPayload, setShowTestPayload] = useState(false)
    const [actualMenu, setActualMenu] = useState('instructions')
    const [payloads, setPayloads] = useState<any>([])
    const [payloadErrorView, setErrorPayloadView] = useState(false);
    const [payloadErrorToShow, setErrorPayloadToShow]:any = useState();
    const [errorReportToShow, setErrorReportToShow]:any = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [selected, setSelected] = useState(null);
    const [ error,setError ] = useState(false);
    const testPayload = {
        bank_id: user?.username,
        callback_url: 'https://my_bank_doc_api/receipts',
        receipt_format: 'svg',
        transaction: {
          reference_id: 'uuid',
          amount: 1230,
          currency: 'EUR',
          customer_id: 'uuid',
          transaction_date: 1708957992406,
          merchant_id: 'muid',
          merchant_name: 'Integration merchant name',
          store_name: 'cote sushi gambetta',
          billing_descriptor: 'surmelin 55',
          siret: '52788142900023',
          payment: {
            bin: '456789',
            last_four: '1234',
            auth_code: 'a27s92',
            scheme: 'VISA',
            transaction_id: 'tid'
          }
        }
      }

    const shouldDisplayNextbutton = useMemo(() => (!!payloads.find((p: any)=>(p?.status ==='OK'))), [payloads])

    const handlePayloadClick = (index:any) => {
        setSelected(index);
    };
    
    
    const tabs:{text:string;number:any;id:string, hideNumber?:boolean}[] = [
        {
            text: 'Instructions',
            number: 1,
            id: 'instructions',
            hideNumber:true
        },
        {
            text: 'Payloads Recues',
            number: payloads.length || 0,
            id: 'payloads',
        },
    ]
    const checkFirstTransactionReceived = async () => {
        try {
            const r = (await API.graphql({
                query: `
                query isTransactionReceived {
  isTransactionReceived {
    pass
    payload {
      status
      date
      payload
    }
  }
}
 

            `,
            })) as any
            const ps=r.data.isTransactionReceived.payload
            setPayloads(ps.map((p:any)=>{
                return {...p, date:p.date.replaceAll('"','').replace('T',' ').split('.')[0]}
            }))
            setFirstTransactionReceived(r.data.isTransactionReceived.pass)
            !r.data.isTransactionReceived.pass && setError(true)
            setTimeout(() => {
                setError(false);
            },5000)
                setIsLoading(false);
        } catch (e: any) {
            console.log(e.errors)
            setFirstTransactionReceived(false)
            setError(true)
            setTimeout(() => {
                setError(false);
            },5000)
            setIsLoading(false);
        }
    }
    const loadPayloads = async () => {
        try {
            const r = (await API.graphql({
                query: `
                    query isTransactionReceived {
                        isTransactionReceived {
                            pass
                            payload {
                            status
                            date
                            payload
                            report
                            }
                        }
                    }
 

                `,
            })) as any
            const ps=r.data.isTransactionReceived.payload
            setPayloads(ps.map((p:any)=>{
                return {...p, date:p.date.replaceAll('"','').replace('T',' ').split('.')[0]}
            }))
        } catch (e: any) {
            console.log(e.errors)
        }
    }

    useEffect(() => {
        loadPayloads()
    }, [])

    const nextPage = groups?.includes('bank')
        ? '/firstTransactionReceived'
        : '/firstReceiptReceived'
    
    return (
        <GridPage>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
            >
                <CircularProgress color="inherit" size={70} />
            </Backdrop>
            <GridPanelRow
                leftChildren={
                    <Box sx={{ paddingRight: 2 }}>
                        {error ? <Alert type='error' label='Transaction non reçue'  /> : ''}
                        {firstTransactionReceived ? (
                            <Box sx={{ padding: 2 }}>
                                <PageTitle paddingBottom={5}>
                                Transaction reçue avec succès 🎉
                                </PageTitle>
                                <SubTitle>
                                Félicitations ! Nous avons correctement reçu votre première transaction. 
                                </SubTitle>
                            </Box>
                        ) : (
                            <Box sx={{ padding: 2 }}>
                                <PageTitle paddingBottom={5}>
                                Envoyez une première transaction 💳
                                </PageTitle>
                                <Tabs
                                    tabs={tabs}
                                    onSelectElement={(x) => {
                                        setActualMenu(tabs[x].id)
                                        loadPayloads()
                                    }}
                                />

                                {actualMenu === 'instructions' && (
                                    <>
                                        <SubTitle>
                                        Pour simuler le matching avec un ticket de caisse dans la sandbox, veuillez envoyer une des payloads de test ci-dessous.
                                        </SubTitle>
                                        <SubTitle>
                                        Veillez à modifier les champs callback_url et receipt_format en suivant les indications de la documentation technique.
                                        </SubTitle>
                                        <SubTitle>
                                        Nous vous enverrons le ticket sur votre callback_url à l'étape suivante.
                                        </SubTitle>
                                    </>
                                )}
                                {actualMenu === 'payloads' && (
                                    <Box
                                        sx={{
                                            display: 'block',
                                            maxWidth: {
                                                xs: '100%',
                                                sm: '100%',
                                                md: '100%',
                                                lg: '100%',
                                                xl: '100%',
                                            },
                                            marginBottom: 5,
                                            marginTop: 5,
                                        }}
                                    >
                                        {payloads.map(
                                            (payload: any, index: number) => (
                                                <Payload
                                                    key={index}
                                                    highlight={
                                                        selected === index
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        console.log(payload)
                                                        handlePayloadClick(
                                                            index
                                                        )
                                                        setErrorPayloadToShow(
                                                            JSON.parse(
                                                                payload?.payload
                                                            )
                                                        )
                                                        setErrorReportToShow(
                                                            payload?.report
                                                        )
                                                        setErrorPayloadView(
                                                            true
                                                        )
                                                        return '1'
                                                    }}
                                                    status={
                                                        payload.status === 'OK'
                                                            ? 'Status OK'
                                                            : 'Status KO'
                                                    }
                                                    timeStamp={payload.date}
                                                />
                                            )
                                        )}
                                    </Box>
                                )}
                            </Box>
                        )}

                        {firstTransactionReceived ? (
                            <Grid
                                gap={2}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    sx={{
                                        marginBottom: {
                                            xs: 1,
                                            sm: 1,
                                            md: 0,
                                            lg: 0,
                                            xl: 0,
                                        },
                                        marginRight: {
                                            xs: 0,
                                            sm: 0,
                                            md: 1,
                                            lg: 1,
                                            xl: 1,
                                        },
                                    }}
                                    label={'Continuer l’intégration'}
                                    variant="secondary"
                                    onClick={() =>
                                        next({
                                            navigate,
                                            isOkForNextStep:
                                                firstTransactionReceived,
                                            integrationStatus,
                                            requiredIntegrationStatus:
                                                'hmacValid',
                                            nextIntegrationStatus:
                                                'postHmacValid',
                                            nextPage: nextPage,
                                            updateUserContext,
                                        })
                                    }
                                />
                            </Grid>
                        ) : actualMenu === 'instructions' ? (
                            <Grid
                                gap={2}
                                sx={{
                                    display: 'flex',
                                    width: '80%',
                                }}
                            >
                                <Button
                                    sx={{
                                        marginBottom: {
                                            xs: 1,
                                            sm: 1,
                                            md: 0,
                                            lg: 0,
                                            xl: 0,
                                        },
                                        marginRight: {
                                            xs: 0,
                                            sm: 0,
                                            md: 1,
                                            lg: 1,
                                            xl: 1,
                                        },
                                    }}
                                    label={
                                        showTestPayload
                                            ? 'Masquer'
                                            : 'Voir les payloads de test'
                                    }
                                    variant="secondary"
                                    onClick={() =>
                                        setShowTestPayload(!showTestPayload)
                                    }
                                />

                                <Button
                                    label="J'ai envoyé la requête"
                                    variant="primary"
                                    onClick={() => {
                                        setIsLoading(true)
                                        checkFirstTransactionReceived()
                                    }}
                                />
                            </Grid>
                        ) : (
                            <Grid
                                gap={2}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    label="Recharger les payloads"
                                    variant="primary"
                                    onClick={() => {
                                        loadPayloads()
                                        return 1
                                    }}
                                />
                                {shouldDisplayNextbutton&&<Button
                                    label="Continuer"
                                    variant="secondary"
                                    onClick={() => {
                                        setIsLoading(true)
                                        checkFirstTransactionReceived()
                                    }}
                                />}
                            </Grid>
                        )}

                        <Grid item>
                            {actualMenu === 'instructions' && showTestPayload && (
                                <Grid item>
                                    <JsonDisplay
                                        Json={JSON.stringify(
                                            testPayload,
                                            null,
                                            4
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                }
                rightChildren={

                    payloadErrorView && actualMenu === 'payloads' ? (
                        <Grid
                            container
                            direction={'column'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            alignContent={'center'}
                            height={'100%'}
                        >
                            {errorReportToShow && (
                                <Chip color="error" label={errorReportToShow} />
                            )}

                            <JsonDisplay
                                Json={JSON.stringify(
                                    payloadErrorToShow,
                                    null,
                                    4
                                )}
                            />
                        </Grid>
                    ) : (
                        <Card
                            timeLine
                            sx={{
                                width: '40vw',
                                height: '100%',
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'flex',
                                    lg: 'flex',
                                    xl: 'flex',
                                },
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {firstTransactionReceived ? (
                                <TransactionReceived
                                    sx={{ width: 300, height: 80, zIndex: 2 }}
                                    amount={0}
                                    date={new Date().toLocaleString('fr-FR')}
                                    partnerLogo={''}
                                    storeName="My Store"
                                />
                            ) : (
                                <TransactionReceived
                                    skeleton
                                    sx={{ width: 300, height: 80, zIndex: 2 }}
                                    amount={0}
                                    date={new Date().toLocaleString('fr-FR')}
                                    partnerLogo={''}
                                    storeName="My Store"
                                />
                            )}
                        </Card>
                    )
                }
            />
        </GridPage>
    )
}
