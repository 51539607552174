import { Card } from "@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms"
import { Typography } from "@mui/material"
import { BankSteps } from "./BankSteps"
import { PosSteps } from "./PosSteps"
import { GridCenteredRow } from "../layout/GridCenteredRow"

export const InstallationSteps = ({group}:any) => {
    return (
        <GridCenteredRow container paddingBottom={5} large>
        <Card sx={{padding:5}}>
        <Typography fontFamily='Ubuntu' textAlign={'start'} sx={{ whiteSpace:{xs:'wrap',lg:'nowrap'},background:'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',WebkitBackgroundClip: 'text', WebkitTextFillColor: '#fff', MozBackgroundClip: 'text', backgroundClip: 'text', color: '#FFF', fontSize: {xs:18,sm:18,md:22,lg:22,xl:28}, lineHeight: 'normal',paddingBottom:2,paddingTop:2}}>
            Quelles sont les étapes de l’intégration ?
        </Typography>
        <Typography fontFamily='Ubuntu' sx={{ width:{xs:'100%',lg:'100%'},background:'linear-gradient(104deg, #53DECE 0%, #30F698 100%)',WebkitBackgroundClip: 'text', WebkitTextFillColor: '#fff', MozBackgroundClip: 'text', backgroundClip: 'text', color: '#FFF', fontSize: {xs:10,sm:10,md:14,lg:16,xl:20}, lineHeight: 'normal',paddingBottom:4}}>
        Après avoir validé l'intégration dans la sandbox, vous pourrez demander un accès à la production.
        </Typography>
        {
            group === 'bank' ? (
                <BankSteps />
            ) : group === 'pos' ? (
                <PosSteps />
            ) : ''
        }
    </Card>
    </GridCenteredRow>
    )
}