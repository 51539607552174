import { GridCenteredRow } from "@killbillsdev/killbills-ui/lib/components/GridCenteredRow";
import { Button } from "@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms";
import { useNavigate } from "react-router-dom"

export const ButtonStartInstallation = () => {
    const navigate = useNavigate();
    return (
        <GridCenteredRow paddingTop={5} paddingBottom={5}>
            <Button variant='primary' label="Démarrer l'intégration"  onClick={() => navigate('/chooseLanguage')} />
        </GridCenteredRow>
    )

}