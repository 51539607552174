import { Grid } from '@mui/material'
import { useState } from 'react'
import { updateIntegrationStatus,updateIsHelpNeeded } from '../utils'
import { useUserContext } from '../contexts/NavigationContext'
import { GridPanelRow } from '../layout/GridPanelRow'
import { GridPage } from '../layout/GridPage'
import { PageTitle, SubTitle } from '../layout/Typographies'
import { Alert, Card, ReceiptReceived } from '@killbillsdev/killbills-ui'
import { Button } from '@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms/Button'
import { API } from 'aws-amplify'

export const FirstTransactionReceivedPage = () => {
    const { updateUserContext } = useUserContext()
    const [callbackReceived, setCallbackReceived] = useState(false)
    const [alertVisible,setAlertVisible] = useState(false);
    const completeStagingIntegration = async () => {
        if (!callbackReceived) {
            setCallbackReceived(true)
            await updateIntegrationStatus(
                'transactionReceived',
                updateUserContext
            )
        }
    }

    const handleReceiptNotReceived = async () => {
        setAlertVisible(true);
        await updateIsHelpNeeded(
                    'true',
                    updateUserContext
                )
        try {
            const r = (await API.graphql({
                query: `
                    query isHelpNeeded {
                            isHelpNeeded {
                                 pass
                            }
                        }
                `,
            })) as any
            if (r.data.isHelpNeeded.pass === true) {
                updateIsHelpNeeded('false',updateUserContext);
             }
         } catch (e: any) {
              console.log(e.errors)
         }
        setTimeout(() => {
            setAlertVisible(false)
        },4000);
    };

    return (
        <GridPage>
            <GridPanelRow
                leftChildren={
                    <Grid container xs={12} md={8}>
                        {
                            alertVisible &&  <Alert sx={{marginBottom:5}} type={'success'} label="l'équipe KillBills à été informée et revient vers vous au plus vite !" />  
                        }
                     
                        {!callbackReceived ? (
                            <Grid container item>
                                <PageTitle paddingBottom={5}>
                                Récupérez son premier ticket 🧾
                                </PageTitle>
                                <SubTitle>
                                Nous vous avons envoyés le ticket de caisse test au format choisi sur votre callback_url.
Avez-vous correctement reçu la payload ?
                                </SubTitle>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    xl={6}
                                    lg={6}
                                    sm={12}
                                    padding={2}
                                >
                                    <Button
                                        variant="primary"
                                        label="C'est reçu !"
                                        onClick={() =>
                                            !callbackReceived
                                                ? completeStagingIntegration()
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    xl={6}
                                    lg={6}
                                    sm={12}
                                    padding={2}
                                >
                                    <Button
                                        variant="secondary"
                                        onClick={() => handleReceiptNotReceived()}
                                        label="Je n'ai rien Reçu"
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid>
                                <PageTitle paddingBottom={5}>
                                    Ticket Reçu ! L'installation est terminée
                                </PageTitle>

                                <SubTitle>
                                    L'équipe KillBills va revenir vers vous très
                                    prochainement avec les différentes
                                    informations sur votre clé HMAC Production
                                </SubTitle>
                            </Grid>
                        )}
                    </Grid>
                }
                rightChildren={
                    <Card
                        timeLine
                        sx={{
                            width: '40vw',
                            height: '100%',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'flex',
                                lg: 'flex',
                                xl: 'flex',
                            },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {callbackReceived ? (
                            <Grid
                                style={{
                                    fontSize: '15em',
                                    zIndex: 2,
                                }}
                            >
                                &#10024;
                            </Grid>
                        ) : (
                            <ReceiptReceived
                            skeleton
                            sx={{ width: 300, height: 400, zIndex: 2 }}
                            amount={0}
                            date={new Date().toLocaleDateString()}
                            partnerLogo={''}
                            storeName={''}
                            storeAddress={'receiptReceived?.storeAddress'}
                            items={'receiptReceived?.items'}
                            partnerName={'receiptReceived?.partnerName'}
                            id={'receiptReceived?.id'}
                        />
                    )}
                    </Card>
                }
            />
        </GridPage>
    )
}

