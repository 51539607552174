/* eslint-disable react/jsx-no-undef */
import { Grid } from "@mui/material";
import { PageTitle, SubTitle } from "../layout/Typographies";
import { Button, CopyField } from "@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms";
import { useUserContext } from "../contexts/NavigationContext";

export const GetStoresList = (setIsLoading:any,handleStoresListReceived:any) => {

    const { apiKey,integrationOption,languageOption } = useUserContext()
    
    const  SdkInstallInstructions = (languageOption:any) => {
        return instructions[languageOption].install;
    }
    
    const SdkRequestInstructions = (languageOption:any,request:string) => {
        return instructions[languageOption][request];
    }
    
    const instructions:any = {
        NodeJS: {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le SDK avec : ' value='npm i @killbillsdev/node-sdk' />,
            getStores: <CopyField sx={{paddingBottom:2}} textArea language='javascript' label='Executez la méthode comme suit : '
            value={`import { getStores } from '@killbills-dev/killbills-sdk';

const main = async () => {
    const partnerStores = await getStores('preprod','${apiKey}');
    return partnerStores;
};
            `}
            />
        },
        Python: {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le SDK avec : ' value='pip install killbills-sdk' />,
            getStores:<CopyField sx={{paddingBottom:2}} textArea language='python' label='Executez la méthode comme suit : ' value={`import killbills_sdk from killbills_sdk.getStores
import get_stores

print(get_stores('preprod','${apiKey}'))`} />,
        },
      
        Java: {
            install:<CopyField sx={{paddingBottom:2}} textArea language='xml' label='Installez le SDK avec : ' value='<dependency>
    <groupId>io.github.killbillsdev</groupId>
    <artifactId>KillBills_sdk</artifactId>
    <version>0.0.1</version>
</dependency>' />,
            getStores:<CopyField sx={{paddingBottom:2}} textArea language='java' label='Executez la méthode comme suit : ' value={`import com.killbills.Sdk;

Sdk.getStores("preprod", "${apiKey}");`} />,
        },
        Go: {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le SDK avec : ' value='go get github.com/killbillsdev/go_sdk/go_sdk' />,
            getStores:<CopyField sx={{paddingBottom:2}} textArea language='go' label='Executez la méthode comme suit : ' value={`import (sdk "github.com/killbillsdev/go_sdk/go_sdk")
    
sdk.GetStores("preprod", "${apiKey}"); `} />,
        },
        'C#': {
            install:<CopyField sx={{paddingBottom:2}} label='Installez le SDK avec : ' value='dotnet add package Killbills_sdk --version 0.0.5' />,
            getStores:<CopyField sx={{paddingBottom:2}} textArea language='csharp' label='Executez la méthode comme suit : ' value={`using CSharpSDK;
using System.Text.Json;
            
var sdk = new KillBills_Sdk();
var result = await sdk.GetStoresAsync("preprod","${apiKey}");
            
Console.WriteLine(JsonSerializer.Serialize(result)); `} />,
        },
    }

    if (integrationOption === 'Intégrer grâce à notre SDK') {
       return (
            <Grid xs={12} md={10}>
                <PageTitle paddingBottom={5}>
                Récupérez la liste des marchands{' '}🏪
                </PageTitle>
                <SubTitle sx={{fontFamily:'Ubuntu', paddingBottom:2,fontSize:16, fontWeight:500}}>
                Cette étape consiste à récupérer la liste des marchands connectés à la plateforme KillBills. Cela permettra à la banque de n'envoyer que des transactions effectuées chez un marchand compatible.
                </SubTitle>
                <SubTitle sx={{paddingBottom:2,fontSize:16, fontWeight:500,fontFamily:'Ubuntu'}}>
                    Commencez par utiliser la méthode getStores.
                    Vous devez en retour recevoir la
                    liste des magasins.
                </SubTitle>
                {SdkInstallInstructions(languageOption)}
                {SdkRequestInstructions(languageOption,'getStores')}
                <Button label="J'ai envoyé la requête" variant="primary"
                    onClick={() => {
                        setIsLoading(true);
                        handleStoresListReceived()
                    }}
                />
            </Grid>
        )
    } else if (integrationOption === 'Intégrer sans notre SDK') {
        return (
            <Grid xs={12} md={10}>
            <PageTitle paddingBottom={5}>
            Récupérez la liste des marchands
            </PageTitle>
            <SubTitle>
                Commencez par envoyer une requête à cette
                addresse. Vous devez en retour recevoir la
                liste des magasins.
            </SubTitle>

            <CopyField
                label="Api Store"
                value="https://w.preprod.killbills.dev/stores"
            />
            <CopyField
                sx={{ paddingBottom: 2 }}
                label="Token"
                value={apiKey ||''}
            />
            <CopyField
                sx={{ paddingBottom: 2 }}
                label="Requête"
                value={`curl --request GET \ --url https://w.preprod.killbills.dev/stores \ --header 'Authorization: ${apiKey}'`}
                textArea
            />

            <Button
                label="J'ai envoyé la requête"
                variant="primary"
                onClick={() => {
                    setIsLoading(true);
                    handleStoresListReceived()
                }}
            />
        </Grid>
        )
    }
}