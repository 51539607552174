import { Backdrop, CircularProgress, Grid } from '@mui/material'
import { useState } from 'react'
import { Card } from '@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms/Card'
import { GridPanelRow } from '../layout/GridPanelRow'
import { useUserContext } from '../contexts/NavigationContext'
import { GridPage } from '../layout/GridPage'
import { PageTitle, SubTitle } from '../layout/Typographies'
import { Button } from '@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms'
import { StoresList } from '@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms/StoresList'
import axios from 'axios'
import { next } from '../utils'
import { GetStoresList } from '../molecules'

export const GetStoresListPage = () => {

    const { navigate, integrationStatus, updateUserContext, apiKey } = useUserContext()
    const [storesListReceived, setStoresListReceived] = useState(false)
    const [list, setList] = useState([])
    const [ isLoading, setIsLoading ] = useState(false);

    const handleStoresListReceived = async () => {
        try {
            const storesLst = await axios.get('https://w.preprod.killbills.dev/stores?limit=6', {
                headers: { Authorization: apiKey },timeout:10000
            })
            console.log('STORES LIST: ', storesLst);
            if (storesLst?.data?.items) {
                setStoresListReceived(true)
                setList(storesLst?.data?.items)
                setIsLoading(false);
                return 'ok';
            }
        } catch (error) {
            setIsLoading(false)
            console.error('erreur call', error)
            return 'error';
        }
    }

    const handleContinueInstallation = async () => {
        try {
            if (integrationStatus === 'started') {
                next({
                    navigate,
                    isOkForNextStep: true,
                    integrationStatus,
                    requiredIntegrationStatus: 'started',
                    nextIntegrationStatus: 'storesStepValid',
                    nextPage: '/getHmacKey',
                    updateUserContext,
                })
            } else {
                navigate('/getHmacKey')
            }
        } catch (error) {
            console.error('Error', error)
        }
    }

    return (
        <GridPage>
             <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" size={70} />
            </Backdrop>
            <GridPanelRow
                leftChildren={
                    <Grid container xs={12} md={10}>
                        {storesListReceived && list ? (
                            <Grid item xs={12} md={10}>
                                <PageTitle paddingBottom={5}>
                                Liste des marchands récupérée avec succès 🎉
                                </PageTitle>
                                <SubTitle>
                                Félicitations, vous avez accédé à la liste des marchands connectés au réseau KillBills ! Cette liste vous permettra de filtrer les transactions bancaires à envoyer.
                                </SubTitle>
                                <Button
                                    sx={{ marginTop: 5 }}
                                    label="Continuer l'intégration"
                                    variant="primary"
                                    onClick={() => handleContinueInstallation()}
                                />
                            </Grid>
                        ) : (
                            GetStoresList(setIsLoading,handleStoresListReceived)
                        )}
                    </Grid>
                }
                rightChildren={
                    <Card
                        timeLine
                        sx={{
                            width: '40vw',
                            height: '100%',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'flex',
                                lg: 'flex',
                                xl: 'flex',
                            },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <StoresList
                            sx={{
                                width: 400,
                                height: 'unset',
                                maxHeight:'310px',
                                paddingBottom: 2,
                                zIndex: 1,
                            }}
                            storesList={list}
                            skeleton={!storesListReceived}
                        />
                    </Card>
                }
            />
        </GridPage>
    )
}
