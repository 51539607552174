/* eslint-disable jsx-a11y/anchor-is-valid */
import { GridCenteredRow } from '../layout/GridCenteredRow'
import { GridPage } from '../layout/GridPage'
import { Grid } from '@mui/material'

export const NotFoundPage = () => {
    return (
        <GridPage haloDown>
            <GridCenteredRow paddingBottom={2}>
                <div className="error-page">
                    <div>
                        <h1 data-h1="404">404</h1>
                        <Grid className="emoji">&#129335;</Grid>
                    </div>
                </div>
            </GridCenteredRow>
        </GridPage>
    )
}
