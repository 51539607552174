
export const StepOneSvg = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_336_4455)">
        <rect width="40" height="40" rx="20" fill="#363247"/>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="rgb(123,224,197)" font-size="14" font-family="Ubuntu"  dy=".1em"  transform="scale(2) translate(-10, -10)">1</text>
      </g>
      <defs>
        <filter id="filter0_i_336_4455" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="12"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_336_4455"/>
        </filter>
      </defs>
    </svg>
    
    
  )
} 




export const StepTwoSvg = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_336_4455)">
        <rect width="40" height="40" rx="20" fill="#363247"/>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="rgb(123,224,197)" font-size="14" font-family="Ubuntu"  dy=".1em"  transform="scale(2) translate(-10, -10)">2</text>
      </g>
      <defs>
        <filter id="filter0_i_336_4455" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="12"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_336_4455"/>
        </filter>
      </defs>
    </svg>
    
    
  )
} 


export const StepThreeSvg = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_336_4455)">
        <rect width="40" height="40" rx="20" fill="#363247"/>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="rgb(123,224,197)" font-size="14" font-family="Ubuntu"  dy=".1em"  transform="scale(2) translate(-10, -10)">3</text>
      </g>
      <defs>
        <filter id="filter0_i_336_4455" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="12"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_336_4455"/>
        </filter>
      </defs>
    </svg>
    
    
  )
} 

export const StepFourSvg = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_336_4455)">
          <rect width="40" height="40" rx="20" fill="#363247"/>
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="rgb(123,224,197)" font-size="14" font-family="Ubuntu"  dy=".1em"  transform="scale(2) translate(-10, -10)">4</text>
        </g>
        <defs>
          <filter id="filter0_i_336_4455" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="12"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_336_4455"/>
          </filter>
        </defs>
      </svg>
      
      
    )
} 

export const StepFiveSvg = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_336_4455)">
        <rect width="40" height="40" rx="20" fill="#363247"/>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="rgb(123,224,197)" font-size="14" font-family="Ubuntu"  dy=".1em"  transform="scale(2) translate(-10, -10)">5</text>
      </g>
      <defs>
        <filter id="filter0_i_336_4455" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="12"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_336_4455"/>
        </filter>
      </defs>
    </svg>
    
    
  )
} 
