import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
//import { ApolloClient, InMemoryCache/* , ApolloProvider  */} from '@apollo/client';
/* import { I18nextProvider } from 'react-i18next';
import i18n from './i18n-config.ts'; */

/* const client = new ApolloClient({
  uri: import.meta.env.VITE_REACT_APP_API_ENDPOINT,
  cache: new InMemoryCache(),
}); */

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App
            signOut={function (): void {
                throw new Error('Function not implemented.')
            }}
            user={undefined}
        />
    </React.StrictMode>
)
