import { GridCenteredRow } from '../layout/GridCenteredRow'
import { Typography } from '@mui/material'
import { styles } from '../styles'

export const HeadingTitle = () => {
    return (
        <GridCenteredRow paddingBottom={2}>
            <Typography
                textAlign={'center'}
                fontFamily="Ubuntu"
                sx={styles.headingTitleFirstText}
            >
                Bienvenue sur la sandbox  {' '}
                <span style={styles.headingTitleSecondText}>
                    KillBills {' '}
                </span>
                👋
            </Typography>
        </GridCenteredRow>
    )
}
